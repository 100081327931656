import React, { useEffect } from "react";
import "./login.css";
import { useNavigate } from "react-router-dom";
import companyLogo from "../../assets/images/logo.svg";
import "react-toastify/dist/ReactToastify.css";
import { GoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getGoogleLoggedInUser } from "src/redux/login/action";

const Login = ({ setAuth }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = localStorage.getItem("user");
  const token = localStorage.getItem("token");

  const googleLogin = async (googleResponse) => {
    const data = {
      tokenId: googleResponse.credential
    };
    const res = await dispatch(getGoogleLoggedInUser(data));
    if (res.data.code === 200) {
      toast.success(`Welcome ${res.data.data.name}`);
      navigate("/ats/candidate-overview");
      setAuth(localStorage.getItem("token"));
      localStorage.setItem("userAuth", "true");
    }
  };

  useEffect(() => {
    if (user && token) {
      navigate("/ats/candidate-overview");
    } else {
      navigate("/");
    }
  }, [user]);

  return (
    <div>
      <div className="login-logo">
        <img src={companyLogo} alt="Sketch One Logo" />
      </div>
      <div className="login-form">
        <h3>Sign In</h3>
        <div className="input-group">
          <GoogleLogin
            onSuccess={googleLogin}
            onError={(error) => {
              console.log("Login Failed", error);
            }}
            hosted_domain=""
            ux_mode={"popup"}
            text="Sign In"
          />
        </div>
      </div>
    </div>
  );
};
export default Login;
