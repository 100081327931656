import React, { useState } from "react";
import { dateFormater } from "src/utils/utils";
import { DateRangePicker } from "rsuite";
import "./filter.css";
import moment from "moment";
import {
  candidateSkillSetsArr,
  candidateStatusArr,
  NoticePeriodArr,
  SourceArr
} from "src/utils/uiConstants";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const initialValue = {
  currentJobTitle: "",
  noticePeriod: "",
  status: "",
  source: "",
  interviewBy: "",
  totalExperience: "",
  startDate: "",
  endDate: ""
};

function Filters({
  close,
  filterCandidate,
  allCandidates,
  query,
  setQuery,
  clearFilter,
  filterTimevalue,
  filterInterviewedBy,
  setFilterTimeValue,
  overWriteClass = null
}) {
  const location = useLocation();
  const currentPath = location.pathname.split("/")[1];
  const [tempQuery, setTempQuery] = useState();
  const handleUpdate = (e) => {
    const { name, value } = e.target;
    const tempQueryValues = { ...tempQuery, [name]: value };
    setTempQuery(tempQueryValues);
    handleClick(tempQueryValues);
    setQuery(tempQueryValues);
  };

  const handleDate = (e) => {
    if (e === null) {
      setFilterTimeValue([]);
      let data = { ...tempQuery };
      data.startDate = "";
      data.endDate = "";
      const result = { ...tempQuery, startDate: "", endDate: "" };

      setTempQuery(result);
      setQuery(data);
      handleClick(result);
    } else {
      setFilterTimeValue(e);
      let data = { ...tempQuery };
      data.startDate = moment(e[0]).format("YYYY/MM/DD");
      data.endDate = moment(e[1]).format("YYYY/MM/DD");
      setTempQuery(data);
      handleClick(data);
    }
  };

  const handleClick = (tempQuery) => {
    const temp = {};
    for (let key in tempQuery) {
      if (tempQuery[key] === "") {
        continue;
      } else if (key === "startDate" || key === "endDate") {
        if (tempQuery[key] === "") {
          continue;
        } else {
          temp[key] = dateFormater(tempQuery[key], "YYYY/MM/DD");
        }
      } else {
        temp[key] = tempQuery[key];
      }
    }
    setTempQuery(temp);
    setQuery(temp);
    filterCandidate(temp);
    close();
  };
  useEffect(() => {
    if (query !== null) {
      setTempQuery({ ...query });
    } else {
      setTempQuery(initialValue);
    }
  }, [query]);

  return (
    <div>
      <div>
        <div className={`statusForm ${overWriteClass ? overWriteClass : ""}`}>
          <div className="input-group hide">
            <label>Status</label>
            <select onChange={handleUpdate} name="status" value={tempQuery?.status}>
              <option value="">Please Select</option>
              {candidateStatusArr.map((opt, v) => (
                <option key={v} value={opt}>
                  {opt}
                </option>
              ))}
            </select>
          </div>
          <div className="input-group">
            <label>Job Openings</label>
            <select
              onChange={handleUpdate}
              name="currentJobTitle"
              value={tempQuery?.currentJobTitle}
            >
              <option value="">Please Select</option>
              {candidateSkillSetsArr.map((opt, v) => (
                <option key={v} value={opt}>
                  {opt}
                </option>
              ))}
            </select>
          </div>
          {currentPath !== "reports" && (
            <div className="input-group">
              <label>Notice Period</label>
              <select
                onChange={handleUpdate}
                name="noticePeriod"
                value={tempQuery?.noticePeriod}
              >
                <option value="">Please Select</option>
                {NoticePeriodArr.map((opt, v) => (
                  <option key={v} value={opt}>
                    {opt}
                  </option>
                ))}
              </select>
            </div>
          )}

          {currentPath !== "reports" && (
            <div className="input-group">
              <label>Source</label>
              <select
                onChange={handleUpdate}
                name="source"
                value={tempQuery?.source}
              >
                <option value="">Please Select</option>
                {SourceArr.map((opt, v) => (
                  <option key={v} value={opt}>
                    {opt}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="input-group">
            <label>Added by</label>
            <select
              onChange={handleUpdate}
              name="interviewBy"
              value={tempQuery?.interviewBy}
            >
              <option value="">Please Select</option>
              {filterInterviewedBy?.length ? (
                filterInterviewedBy.map((opt, v) => (
                  <option key={v} value={opt?.name}>
                    {opt?.name}
                  </option>
                ))
              ) : (
                <option value="No Interviewers ">No Interviewers </option>
              )}
            </select>
          </div>
          <div className="input-group">
            <label>Start and End Date</label>
            <DateRangePicker
              className="w-100 h--50 date_picker_range"
              showOneCalendar={true}
              onOk={(event) => handleDate(event)}
              onChange={(event) => handleDate(event)}
              cleanable={true}
              ranges={[]}
              placeholder={"Please Select"}
              format={"dd-MM-yyyy"}
              defaultValue={filterTimevalue}
              value={filterTimevalue}
            />
          </div>
          <div>
            {query?.currentJobTitle ||
            query?.noticePeriod ||
            query?.status ||
            query?.source ||
            query?.interviewBy ||
            query?.totalExperience ||
            query?.startDate ? (
              <span
                className="clear_button_filter"
                onClick={() => {
                  // let status = JSON.parse(localStorage.getItem("statusCustom"));
                  localStorage.setItem("filters", JSON.stringify({}));
                  clearFilter();

                  allCandidates(1, 10, "", {} ?? "");
                  close();
                }}
              >
                Clear Filter
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Filters;
