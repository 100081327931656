import React from "react";
import { sideBarAdminData } from "src/components/sidebar/sideBarData";
import AdminPanelSideBar from "src/components/subSidebar/AdminPanelSideBar";
import AdminPanel from "./AdminPanel";

const admin = () => {
  return (
    <div className="dflex">
      <div className="main-wrapper">
        <div className="sidebar_admin">
          <AdminPanelSideBar subChildren={sideBarAdminData} />
        </div>
        <div className="main-body">{<AdminPanel />}</div>
      </div>
    </div>
  );
};

export default admin;
