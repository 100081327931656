import React, { useState, useEffect } from "react";
import { DateRangePicker } from "rsuite";
import { useNavigate } from "react-router-dom";
import "./analytics.css";
import "rsuite/dist/rsuite.css";
import Loader from "../../components/loader";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardOfferAccepted } from "src/redux/candidateDashboard/action";
import NoDataFound from "src/components/noDataFound/index";
import Card from "src/components/analytics/Card";
import { candidateSkillSetsArr } from "src/utils/uiConstants";
import moment from "moment";
import SkeletonLoader from "src/components/skeletonLoader/index";

function Atsdashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardOfferAccepted = useSelector(
    (state) => state?.candidateDashboard?.dashboardOfferAccepted
  );
  let userAuth = localStorage.getItem("userAuth");
  const [value, setValue] = React.useState(undefined);
  const [tech, setTech] = useState("");
  const [atsLoad, setAtsLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [contentBoxLoader, setContentBoxLoader] = useState(false);

  useEffect(() => {
    if (userAuth === "true") {
      offerAccepted(tech, value);
    } else {
      localStorage.clear();
      localStorage.setItem("userAuth", "false");
      navigate("/");
    }
  }, [tech, value, userAuth]);

  const offerAccepted = async (tech = "", value = "") => {
    setContentBoxLoader(true);
    if (tech && !value) {
      await dispatch(getDashboardOfferAccepted({ currentJobTitle: tech }));
    } else if (tech && value) {
      let startDate = moment(value[0]).format("YYYY-MM-DD");
      let endDate = moment(value[1]).format("YYYY-MM-DD");
      await dispatch(
        getDashboardOfferAccepted({ currentJobTitle: tech, startDate, endDate })
      );
    } else if (!tech && value) {
      let startDate = moment(value[0]).format("YYYY-MM-DD");
      let endDate = moment(value[1]).format("YYYY-MM-DD");
      await dispatch(getDashboardOfferAccepted({ startDate, endDate }));
    } else if (!tech && !value) {
      await dispatch(getDashboardOfferAccepted());
    }
    setContentBoxLoader(false);

    setIsLoading(false);
    setAtsLoad(false);
  };

  return !isLoading && userAuth === "true" ? (
    <>
      <div className="stages">
        <div className="heading-section">
          <h4>Recent Updates</h4>

          <div className="detail-status">
            <p>Filter By :</p>

            <div className="filter-select" style={{ position: "relative" }}>
              {
                <div className="CD-Dash">
                  {/* <select
                    className="hhh100"
                    value={interviewBy}
                    onChange={(e) => {
                      setInterviewBy(e.target.value);
                    }}
                  >
                    <option value="all"> Select Interviewer</option>
                    {interviewedByArr?.length ? (
                      interviewedByArr.map((opt, v) => (
                        <option key={v} value={opt.name}>
                          {opt.name}
                        </option>
                      ))
                    ) : (
                      <option value={"no interviwers"} disabled>
                        No Interviewer{" "}
                      </option>
                    )}
                  </select> */}

                  <select
                    className="hhh100"
                    style={{ margin: "0px 10px" }}
                    onChange={(e) => {
                      setTech(e.target.value);
                    }}
                    value={tech}
                  >
                    <option value="">Select Role</option>
                    {candidateSkillSetsArr.map((opt, v) => (
                      <option key={v} value={opt}>
                        {opt}
                      </option>
                    ))}
                  </select>

                  <DateRangePicker
                    showOneCalendar={true}
                    ranges={[]}
                    placeholder={"DD-MM-YYYY~DD-MM-YYYY"}
                    defaultValue={value}
                    format={"dd-MM-yyyy"}
                    onChange={(event) => {
                      setValue(event ?? []);
                    }}
                    cleanable={true}
                    onClean={() => setValue(undefined)}
                  />
                </div>
              }
            </div>
          </div>
        </div>
        {atsLoad ? (
          <Loader />
        ) : (
          <>
            {/* <div className="candidate-stages">
              <div>
                <p>Candidates</p>
                <p>{dashboardData?.Candidates}</p>
              </div>
              <div>
                <p>Screening</p>
                <p>{dashboardData?.screening}</p>
              </div>
              <div>
                <p>Round 1</p>
                <p>{dashboardData?.Round_1}</p>
              </div>
              <div>
                <p> Task Verification</p>
                <p>{dashboardData?.taskVerification}</p>
              </div>
              <div>
                <p>Round 2</p>
                <p>{dashboardData?.Round_2}</p>
              </div>

              <div>
                <p>HR Round</p>
                <p>{dashboardData?.hrRound}</p>
              </div>
              <div>
                <p>Offered</p>
                <p>{dashboardData?.offered}</p>
              </div>
              <div>
                <p>Offer Accepted</p>
                <p>{dashboardData?.offerAccepted}</p>
              </div>
              <div>
                <p>Offer Declined</p>
                <p>{dashboardData?.OfferDeclined}</p>
              </div>
              <div>
                <p>Joined</p>
                <p>{dashboardData?.joined}</p>
              </div>
              <div>
                <p>Rejected</p>
                <p>{dashboardData?.rejected}</p>
              </div>
            </div> */}

            {dashboardOfferAccepted ? (
              <div className="emp-dashboard-overview">
                <div className="data-card">
                  <div className="data-card-heading">
                    <h4>
                      Offered ({dashboardOfferAccepted?.getCandidatesoffered.length}){" "}
                    </h4>
                  </div>
                  <div>
                    {" "}
                    {!contentBoxLoader ? (
                      <div className="data-card-data-wrapper">
                        {dashboardOfferAccepted?.getCandidatesoffered?.length >=
                        1 ? (
                          dashboardOfferAccepted?.getCandidatesoffered.map(
                            (f, index) => <Card key={index} data={f} />
                          )
                        ) : (
                          <NoDataFound />
                        )}
                      </div>
                    ) : (
                      <SkeletonLoader />
                    )}
                  </div>
                </div>

                <div className="data-card">
                  <div className="data-card-heading">
                    <h4>
                      Offer Accepted (
                      {dashboardOfferAccepted?.getCandidatesofferAccepted.length}){" "}
                    </h4>
                  </div>
                  {!contentBoxLoader ? (
                    <div className="data-card-data-wrapper">
                      {dashboardOfferAccepted?.getCandidatesofferAccepted?.length >=
                      1 ? (
                        dashboardOfferAccepted?.getCandidatesofferAccepted.map(
                          (f, index) => <Card key={index} data={f} />
                        )
                      ) : (
                        <NoDataFound />
                      )}
                    </div>
                  ) : (
                    <SkeletonLoader />
                  )}
                </div>
                {/* <div className="data-card">
                  <div className="data-card-heading">
                    <h4>Candidates Rejected</h4>
                  </div>
                  <div className="data-card-data-wrapper">
                    {dashboardOfferAccepted?.getCandidatesRejected?.length >= 1 ? (
                      dashboardOfferAccepted?.getCandidatesRejected.map(
                        (f, index) => <Card key={index} data={f} />
                      )
                    ) : (
                      <NoDataFound />
                    )}
                  </div>
                </div> */}
                <div className="data-card">
                  <div className="data-card-heading">
                    <h4>
                      Candidates Joined (
                      {dashboardOfferAccepted?.getCandidatesJoined.length}){" "}
                    </h4>
                  </div>
                  {!contentBoxLoader ? (
                    <div className="data-card-data-wrapper">
                      {dashboardOfferAccepted?.getCandidatesJoined?.length >= 1 ? (
                        dashboardOfferAccepted?.getCandidatesJoined.map(
                          (f, index) => <Card key={index} data={f} />
                        )
                      ) : (
                        <NoDataFound />
                      )}
                    </div>
                  ) : (
                    <SkeletonLoader />
                  )}
                </div>
                <div className="data-card">
                  <div className="data-card-heading">
                    <h4>
                      Offer Declined (
                      {dashboardOfferAccepted?.getCandidatesOfferDeclined.length}){" "}
                    </h4>
                  </div>
                  {!contentBoxLoader ? (
                    <div className="data-card-data-wrapper">
                      {dashboardOfferAccepted?.getCandidatesOfferDeclined?.length >=
                      1 ? (
                        dashboardOfferAccepted?.getCandidatesOfferDeclined.map(
                          (f, index) => <Card key={index} data={f} />
                        )
                      ) : (
                        <NoDataFound />
                      )}
                    </div>
                  ) : (
                    <SkeletonLoader />
                  )}
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </>
        )}
      </div>
    </>
  ) : (
    <Loader />
  );
}

export default Atsdashboard;
