import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "axios";
import companyLogo from "src/assets/images/logo.svg";
import "src/components/header/header.css";
import { Popover, Whisper, Avatar } from "rsuite";
import {
  getNameInitials,
  getStorageItem,
  notificationSuccess
} from "src/utils/utils";
import { BASE_URL } from "../../utils/urlContainers";

const Header = ({ setAuth }) => {
  const location = useLocation();
  const isLoggedIn = localStorage.getItem("userAuth");
  const role = JSON.parse(localStorage.getItem("user"));
  const [toggle, setToggle] = useState(true);
  const [subActiveState, setSubActiveState] = useState(true);
  const [textProfile, setTextProfile] = useState("");
  const [visiblePopup, setVisiblePopup] = useState(false);
  const auth = getStorageItem("token");
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(true);
  const [subChildrenSideBar] = useState({
    children: [
      { subChild: "Dashboard", routing: "/ats/dashboard" },
      { subChild: "Candidates", routing: "/ats/candidate-overview" }
    ]
  });

  useEffect(() => {
    if (isLoggedIn === "true") {
      setSubActiveState(false);
    } else {
      localStorage.clear();
      navigate("/");
    }
  }, [isLoggedIn]);

  const handleClickOutside = (event) => {
    if (visiblePopup && !event.target.closest(".user-avatar")) {
      setVisiblePopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [visiblePopup]);

  useEffect(() => {
    async function fetchData() {
      const userInitials = await getNameInitials(role?.name);
      setTextProfile(userInitials);
      setIsLoading(false);
    }
    fetchData();
  }, [role]);

  useEffect(() => {
    if (location.pathname === "/ats/dashboard") {
      setSubActiveState(true);
      setToggle(true);
    } else {
      setSubActiveState(false);
      setToggle(true);
    }
    if (location.pathname === "/admin-panel" || location.pathname === "/reports") {
      setToggle(false);
    }
  }, [location.pathname]);

  const logout = () => {
    setVisiblePopup(false);

    axios.post(`${BASE_URL}/admin/logout`).then(() => {
      localStorage.clear();
      setAuth("");
      navigate("/");
      setSubActiveState(false);
      notificationSuccess("You are logged out.!");
    });
  };

  if (!auth) {
    return;
  } else {
    return (
      !loading && (
        <div className="header-section">
          <div className="header-align">
            <div className="logo-section">
              <img
                className="cursor-pointer"
                onClick={() => navigate("/ats/candidate-overview")}
                src={companyLogo}
                alt="Sketch One Logo"
              />
            </div>
            {subChildrenSideBar && (
              <div className="header-links">
                {role.role !== "Consultancy" && (
                  <ul>
                    <li
                      className={
                        toggle && subActiveState === true ? "active mmt-5" : "mmt-5"
                      }
                    >
                      {toggle && role.role !== "Users" && (
                        <Link
                          to={"/ats/dashboard"}
                          onClick={() => setSubActiveState(true)}
                        >
                          Dashboard
                        </Link>
                      )}
                    </li>
                    <li
                      className={
                        toggle && subActiveState === false ? "active mmt-5" : "mmt-5"
                      }
                    >
                      {toggle && (
                        <Link
                          to={"/ats/candidate-overview"}
                          onClick={() => setSubActiveState(false)}
                        >
                          Candidates
                        </Link>
                      )}
                    </li>
                  </ul>
                )}
              </div>
            )}
          </div>
          <div className="header-profile">
            <Whisper
              placement="bottom"
              trigger={["click", "hover"]}
              full={true}
              on
              open={visiblePopup}
              speaker={
                <Popover>
                  {toggle && role?.role === "Admin" ? (
                    <button
                      className="btn-grey fff pop_button"
                      onClick={() => {
                        navigate("/admin-panel");
                        setVisiblePopup(false);
                      }}
                    >
                      ADMIN PANEL
                    </button>
                  ) : (
                    role?.role === "Admin" && (
                      <button
                        className="btn-grey fff pop_button"
                        onClick={() => {
                          navigate("/ats/candidate-overview");
                          setVisiblePopup(false);
                        }}
                      >
                        CANDIDATES
                      </button>
                    )
                  )}
                  <button className="btn-grey fff pop_button" onClick={logout}>
                    LOGOUT
                  </button>
                </Popover>
              }
            >
              <div className="profile">
                <Avatar
                  circle
                  style={{}}
                  onClick={() => setVisiblePopup(!visiblePopup)}
                  className="user-avatar"
                >
                  {textProfile}
                </Avatar>
              </div>
            </Whisper>
          </div>
        </div>
      )
    );
  }
};

export default Header;
