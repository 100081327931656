import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest
} from "src/utils/httpHelper";
import { candidateOverviewUrl } from "src/utils/urlContainers";
import { attachParams } from "src/utils/urlHelper";

export const getAllCandidates = async (data) => {
  return await getRequest({
    url: attachParams(candidateOverviewUrl?.GET_ALL_CANDIDATES, data)
  });
};

export const getCandidateDetails = async (data) => {
  return await getRequest({
    url: attachParams(candidateOverviewUrl?.CANDIDATE_DETAILS, data)
  });
};

export const getPipelineDetails = async (data) => {
  return await getRequest({
    url: attachParams(candidateOverviewUrl?.PIPELINE_DETAILS, data)
  });
};

export const getSearchCandidateList = async (data) => {
  return await getRequest({
    url: attachParams(candidateOverviewUrl?.SEARCH_CANDIDATE, data)
  });
};
export const getFilterCandidateList = async (data) => {
  return await getRequest({
    url: attachParams(candidateOverviewUrl?.FILTER_CANDIDATE, data)
  });
};

export const deleteCandidate = async (data) => {
  return await deleteRequest({
    url: attachParams(candidateOverviewUrl?.DELETE_CANDIDATE, data)
  });
};

export const addCandidateReqApi = async (data) => {
  return await postRequest({
    url: candidateOverviewUrl?.ADD_CANDIDATE,
    data: data,
    noAuth: true
  });
};

export const duplicateCandidateCheckReqApi = async (data) => {
  return await postRequest({
    url: candidateOverviewUrl.DUPLICATE_CHECK,
    data: data,
    noAuth: true
  });
};

export const editCandidateReqApi = async (data) => {
  return await putRequest({
    url: candidateOverviewUrl?.UPDATE_CANDIDATE,
    data: data,
    noAuth: true
  });
};

export const updateCandidateStatusReqApi = async (data) => {
  return await putRequest({
    url: candidateOverviewUrl?.UPDATE_STATUS,
    data: data,
    noAuth: true
  });
};

export const deleteStatus = async (data) => {
  return await deleteRequest({
    url: attachParams(candidateOverviewUrl?.DELETE_STATUS, data)
  });
};
