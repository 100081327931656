import React from "react";
import "src/components/loader/loader.css";

const Loader = ({ loading, loaderStyles = {} }) => {
  return (
    <>
      <section
        className={loading === "small" ? "loader small" : "loader"}
        style={{ ...loaderStyles }}
      >
        <div className="container_spin">
          <div className="inner_spiner">
            {" "}
            <div className="spinner"></div>
            <h1 className="mmt-20">
              Loading <span className="loading"></span>
            </h1>
          </div>
        </div>
      </section>
    </>
  );
};

export default Loader;
