import React from "react";
import { numberWithCommas } from "src/utils/utils";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";

function Atsdetails(props) {
  const [skillset, setSkillset] = useState([]);
  const { details } = props;
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (props?.details?.skillSet) {
      setSkillset(props.details.skillSet);
    }
  }, [props]);

  return (
    <>
      {/* basic details */}
      <div className="list-view " list={props.id}>
        <h4>Basic Details</h4>
        <ul className="mmt-10 reduce-margin">
          <li>
            <label>Candidate ID</label>
            <span>
              {props.details?.candidateID ? props.details?.candidateID : "-"}
            </span>
          </li>
          <li>
            <label>Full Name</label>
            <span className="ellipsis">
              {props.details?.firstName ? props.details?.firstName : "-"}{" "}
              {props.details?.lastName ? props.details?.lastName : "-"}
            </span>
          </li>
          <li>
            <label>Mobile Number</label>
            <a href={`tel:+91${details?.phoneNumber}`}>
              {details?.phoneNumber ?? "-"}
            </a>
          </li>
          <li>
            <label>Personal Email Address</label>
            <a href={`mailto:${props.details?.email}`}>
              {props.details?.email ? props.details?.email : "-"}
            </a>
          </li>
        </ul>
      </div>
      {/* Professional Detail  */}
      <div className="list-view">
        <h4>Professional Details</h4>
        <ul className="mmt-10">
          <li>
            <label>Total Experience</label>
            <span>
              {props.details?.totalExperience === "0" ? (
                <span>{props.details?.totalMonthOfExperience + " Months"}</span>
              ) : props.details?.totalMonthOfExperience === "0" ? (
                <span>{props.details?.totalExperience + " Years"}</span>
              ) : (
                <span>
                  {props.details?.totalExperience +
                    "." +
                    props.details?.totalMonthOfExperience +
                    " Years"}
                </span>
              )}
            </span>
          </li>
          <li>
            <label>Relevant Experience</label>
            <span>
              {props.details?.relevantExperience === "0" ? (
                <span>
                  {props.details?.totalMonthOfRelevantExperience + " Months"}
                </span>
              ) : props.details?.totalMonthOfRelevantExperience === "0" ? (
                <span>{props.details?.relevantExperience + " Years"}</span>
              ) : (
                <span>
                  {props.details?.relevantExperience +
                    "." +
                    props.details?.totalMonthOfRelevantExperience +
                    " Years"}
                </span>
              )}
            </span>
          </li>
          {(user.role === "Admin" ||
            user.role === "Manager" ||
            user.role === "Recruiters") && (
            <>
              {" "}
              <li>
                <label>Current Salary Per Year</label>
                <span>
                  {props.details?.currentSalaryPerYear
                    ? "₹ " + numberWithCommas(props.details?.currentSalaryPerYear)
                    : "-"}
                </span>
              </li>
              <li>
                <label>Current Salary Per Month</label>
                <span>
                  {props.details?.currentSalaryPerMonth
                    ? "₹ " + numberWithCommas(props.details?.currentSalaryPerMonth)
                    : "-"}
                </span>
              </li>
              <li>
                <label>Expected Salary Per Year</label>
                <span>
                  {props.details?.expectedSalaryPerYear
                    ? "₹ " + numberWithCommas(props.details?.expectedSalaryPerYear)
                    : "-"}
                </span>
              </li>
              <li>
                <label>Expected Salary Per Month</label>
                <span>
                  {props.details?.expectedSalaryPerMonth
                    ? "₹ " + numberWithCommas(props.details?.expectedSalaryPerMonth)
                    : "-"}
                </span>
              </li>{" "}
            </>
          )}
          <li>
            <label>Applied Role</label>
            <span>
              {props.details?.currentJobTitle ? props.details?.currentJobTitle : "-"}
            </span>
          </li>
          <li>
            <label>Skills</label>
            {skillset.length >= 1 ? (
              skillset.map((options, x) =>
                x === skillset.length - 1 ? (
                  <span className="sub-p" key={x}>
                    {" " + options.value + " "}
                  </span>
                ) : (
                  <span className="sub-p" key={x}>
                    {" " + options.value + ","}
                  </span>
                )
              )
            ) : (
              <span>{"-"}</span>
            )}
          </li>
          <li>
            <label>Notice Period</label>
            <span>
              {props.details?.noticePeriod ? props.details?.noticePeriod : "-"}
            </span>
          </li>
          <li>
            <label>Expected Joining Date</label>
            <span>
              {props.details?.expectedJoiningDate
                ? moment(props.details?.expectedJoiningDate).format("DD MMM YYYY")
                : "-"}
            </span>
          </li>
          <li>
            <label>Serving Notice Period</label>
            <span>{props.details?.servedNoticePeriod ? "Yes" : "No"}</span>
          </li>
          <li>
            <label>NP Negotiable</label>
            <span>{props.details?.isNegotiable === true ? "Yes" : "No"}</span>
          </li>
          <li>
            <label>Other Offers </label>
            <span>
              {props.details?.offeredValue ? (
                <span>{props.details?.offeredValue + " " + "LPA"}</span>
              ) : (
                "No Other Offers"
              )}
            </span>
          </li>
          <li>
            <label>Source</label>
            <span>{props?.details?.source || "-"}</span>
          </li>
          <li>
            <label>Added By</label>
            <span>{props?.details?.interviewBy || "-"}</span>
          </li>
          <li></li>
        </ul>
      </div>
      <p className="w-100 add-notes">
        <label>Notes</label>
        <span
          dangerouslySetInnerHTML={{
            __html: props.details.remarks || "-"
          }}
        ></span>
      </p>
      {/* Documents */}

      {/* Address Info */}
      <div className="list-view">
        <h4>Address Details </h4>
        {props.details?.currentAddress !== "" ||
        props.details?.permanentAddress !== "" ? (
          <ul className="address-info mmt-10">
            <li>
              <label>Current Address</label>
              <span>
                {props.details?.currentAddress ? props.details?.currentAddress : "-"}
              </span>
            </li>
            <li>
              <label>Permanent Address</label>
              <span>
                {props.details?.permanentAddress
                  ? props.details?.permanentAddress
                  : "-"}
              </span>
            </li>
          </ul>
        ) : (
          <span className="noInfoAdded">No Address Info Added</span>
        )}
      </div>
      {/* Education Info */}
      <div className="list-view">
        <h4>Education Details </h4>

        {props.details?.educationInfo?.length ? (
          props.details?.educationInfo?.map((field, index) => {
            return (
              <ul className="mmt-10" key={`${field}-${index}`}>
                <li>
                  <label>
                    {Object.values(field.school).length > 0 ? "School" : "School"}
                  </label>
                  <span>{field.school ? field.school : "-"}</span>
                </li>
                <li>
                  <label>
                    {Object.values(field.school).length > 0 ? "Degree" : "Degree"}
                  </label>
                  <span>{field.degree ? field.degree : "-"}</span>
                </li>
                <li>
                  <label>
                    {Object.values(field.school).length > 0
                      ? "Start Date"
                      : "Start Date"}
                  </label>
                  <span>
                    {field.startDate1
                      ? moment(field.startDate1).format("DD MMM YYYY")
                      : "-"}
                  </span>
                </li>
                <li>
                  <label>
                    {" "}
                    {Object.values(field.school).length > 0
                      ? "End Date"
                      : "End Date"}
                  </label>
                  <span>
                    {field.endDate1
                      ? moment(field.endDate1).format("DD MMM YYYY")
                      : "-"}
                  </span>
                </li>
              </ul>
            );
          })
        ) : (
          <span className="noInfoAdded">No Education Info Added</span>
        )}
      </div>
      {/* Experience Info  */}
      <div className="list-view">
        <h4>Experience Details </h4>
        {props.details?.experienceInfo?.length ? (
          props.details?.experienceInfo?.map((fields, indexs) => {
            return (
              <ul className="mmt-10" key={`${fields}-${indexs}`}>
                <li>
                  <label>
                    {Object.values(fields.jobTitle).length > 0
                      ? "JobTitle"
                      : "JobTitle"}
                  </label>
                  <span>{fields.jobTitle ? fields.jobTitle : "-"}</span>
                </li>
                <li>
                  <label>
                    {Object.values(fields.company).length > 0
                      ? "Company"
                      : "Company"}
                  </label>
                  <span>{fields.company ? fields.company : "-"}</span>
                </li>
                <li>
                  <label>
                    {Object.values(fields.exstartDate).length > 0
                      ? "Start Date"
                      : "Start Date"}
                  </label>
                  <span>
                    {fields.exstartDate
                      ? moment(fields.exstartDate).format("DD MMM YYYY")
                      : "-"}
                  </span>
                </li>
                <li>
                  <label>
                    {Object.values(fields.exendDate).length > 0
                      ? "End Date"
                      : "End Date"}
                  </label>
                  <span>
                    {fields.exendDate
                      ? moment(fields.exendDate).format("DD MMM YYYY")
                      : "-"}
                  </span>
                </li>
              </ul>
            );
          })
        ) : (
          <span className="noInfoAdded">No Experience Info Added</span>
        )}
      </div>

      {/* Other Info */}
    </>
  );
}

export default Atsdetails;
