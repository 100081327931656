import React, { useState } from "react";
import { checkIsValid } from "src/utils/utils";
import { validationContainer } from "src/utils/validationConstants";
import { useDispatch } from "react-redux";
import { duplicateCandidateCheck } from "src/redux/candidateOverview/action";

const ConsultancyDashboard = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [pageData, setPageData] = useState({ email: "", phoneNumber: "" });
  const [error, setError] = useState({ email: "", phoneNumber: "" });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setPageData({ ...pageData, [name]: value });
    setError({ ...error, [name]: "" });
  };

  const checkforDuplicate = async () => {
    if (
      pageData?.email &&
      checkIsValid(pageData?.email, validationContainer?.emailFormat) &&
      pageData?.phoneNumber &&
      checkIsValid(pageData?.phoneNumber, validationContainer?.mobileNumberFormat)
    ) {
      setLoader(true);
      let formData = new FormData();
      formData.append("email", pageData["email"]);
      formData.append("phoneNumber", pageData["phoneNumber"]);

      await dispatch(duplicateCandidateCheck(formData, "Fresh Candidate"));
      setTimeout(() => {
        setLoader(false);
      }, 3000);
    } else {
      const err = {
        email: "",
        phonenumber: ""
      };
      if (
        !pageData?.email ||
        !checkIsValid(pageData?.email, validationContainer?.emailFormat)
      ) {
        err.email = "Enter valid Email";
      }
      if (
        !pageData?.phoneNumber ||
        !checkIsValid(pageData?.phoneNumber, validationContainer?.mobileNumberFormat)
      ) {
        err.phoneNumber = "Enter valid Mobile Number";
      }
      setError(err);
    }
  };

  return (
    <div className="consultancy-dashboard">
      <div className="cs-form-container">
        <div className="cs-form-wrapper">
          <h2 className="cs-title">Search for Candidates!</h2>
        </div>
        <div className="cs-form-wrapper">
          <label className="cs-form-label">
            Email<span className="required_label">*</span>
          </label>
          <input
            className="cs-input-form"
            autoComplete="off"
            onWheel={(e) => e.target.blur()}
            onChange={handleInput}
            value={pageData?.email}
            type="text"
            name="email"
            id=""
          />
          {error?.email && (
            <span className="cs__error__message">{error?.email}</span>
          )}
        </div>

        <div className="cs-form-wrapper">
          <label className="cs-form-label">
            {" "}
            Mobile Number<span className="required_label">*</span>
          </label>
          <input
            value={pageData?.phoneNumber}
            autoComplete="off"
            onKeyDown={(e) =>
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
            }
            min="0"
            onWheel={(e) => e.target.blur()}
            name="phoneNumber"
            id=""
            onChange={handleInput}
            className="cs-input-form"
            type="number"
          />
          {error?.phoneNumber && (
            <span className="cs__error__message">{error?.phoneNumber}</span>
          )}
        </div>

        <div className="cs-form-wrapper">
          <button
            className="cs-submit-button"
            onClick={checkforDuplicate}
            disabled={loader}
          >
            {loader ? (
              <div className="button-loader"></div>
            ) : (
              <span> Check Duplicate</span>
            )}
          </button>
        </div>
      </div>
      <div className="cs-banner-container"></div>
    </div>
  );
};

export default ConsultancyDashboard;
