import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./quill.css";

const Editor = ({ value, remarksUpdateHandler }) => {
  return (
    <ReactQuill
      theme="snow"
      onChange={(html) => remarksUpdateHandler(html)}
      value={value}
      modules={Editor.modules}
      formats={Editor.formats}
      bounds={".app"}
      style={{ textTransform: "none" }}
    />
  );
};

export default Editor;

Editor.modules = {
  toolbar: [
    [{ header: "1" }],
    ["bold", "italic", "underline", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"]
  ],
  clipboard: {
    matchVisual: false
  }
};

Editor.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "blockquote",
  "list",
  "bullet",
  "link"
];
