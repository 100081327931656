import React, { useEffect, useState } from "react";

import back from "src/assets/images/close icon.svg";
import { addAdminUser, updateAdminUser } from "src/services/adminPanel/adminPanel";
import {
  checkIsValid,
  notificationError,
  notificationSuccess
} from "src/utils/utils";
import { validationContainer } from "src/utils/validationConstants";

const initialState = {
  name: "",
  email: "",
  role: ""
};

function EditPopup({ close, selectedCandidate, setRender }) {
  const [candidates, setCandidates] = useState(initialState);
  const [loading, setLoading] = useState(true);
  const [modified, setModified] = useState(false);
  const [error, handleError] = useState({ name: null, email: null, role: null });

  useEffect(() => {
    if (selectedCandidate) {
      setCandidates(selectedCandidate);
    } else {
      setCandidates(initialState);
    }
    setLoading(false);
  }, []);

  const handleChangeCandidate = (e) => {
    setCandidates({ ...candidates, [e.target.name]: e.target.value });
    handleError({ ...error, [e.target.name]: null });
    setModified(true);
  };

  const handleCandidate = async () => {
    if (
      candidates.name &&
      candidates.email &&
      candidates.role &&
      modified &&
      checkIsValid(candidates.email, validationContainer.emailFormat)
    ) {
      if (selectedCandidate && modified) {
        //update User
        const response = await updateAdminUser(candidates);
        if (response.data?.code === 200) {
          notificationSuccess(response.data?.message);
          close();
          setRender();
        } else {
          notificationError(response.data?.message ?? "Something went wrong");
        }
      } else if (!selectedCandidate && modified) {
        //post new User
        const response = await addAdminUser(candidates);
        if (response.data?.code === 200) {
          notificationSuccess(response.data?.message);
          close();
          setRender();
        } else {
          notificationError(response.data?.message ?? "Something went wrong");
        }
      }
    } else {
      let nameErr = null;
      let emailErr = null;
      let roleErr = null;

      if (!candidates.name) {
        nameErr = "Enter name";
      }
      if (!candidates.email) {
        emailErr = "Enter email address";
      } else if (
        !checkIsValid(candidates?.email, validationContainer?.emailFormat)
      ) {
        emailErr = "Enter valid email address";
      }
      if (!candidates.role) {
        roleErr = "Select role";
      }
      handleError({ ...error, name: nameErr, email: emailErr, role: roleErr });
    }
    setLoading(false);
  };

  return (
    <>
      <div className="statusModal  ">
        <div className="statusModalContent">
          <div className="statusHead">
            <h3>{selectedCandidate ? "Edit User" : "Add User"}</h3>
            <img src={back} onClick={() => close()} alt="back" width={30} />
          </div>
          <div className="statusForm adminPanelPage">
            <div className="input-group">
              <label>
                Name <span className="required_label">*</span>
              </label>
              <input
                name="name"
                value={candidates.name}
                onChange={handleChangeCandidate}
                className="input-form"
              />
              <span className="required__error__message">{error.name}</span>
            </div>

            <div className="input-group mmt-20">
              <label>
                Email <span className="required_label">*</span>
              </label>
              <input
                name="email"
                value={candidates.email}
                onChange={handleChangeCandidate}
                className="input-form"
              />
              <span className="required__error__message">{error.email}</span>
            </div>
            <div className="input-group mmt-20">
              <label>
                Role <span className="required_label">*</span>
              </label>
              <select
                name="role"
                onChange={handleChangeCandidate}
                value={candidates.role}
              >
                <option hidden value="">
                  Please Select
                </option>
                <option value="Admin">Admin</option>
                <option value="Manager">Manager</option>
                <option value="Recruiters">Recruiters</option>
                <option value="Interviewers">Interviewers</option>
                <option value="Consultancy">Consultancy</option>
                <option value="Users">Users</option>
              </select>
              <span className="required__error__message">{error.role}</span>
            </div>

            <div className="btn-section mmt-20">
              <button
                className="btn-blue"
                disabled={loading}
                onClick={() => {
                  setLoading(true);
                  handleCandidate();
                }}
              >
                {loading ? <i className="fa fa-circle-o-notch fa-spin"></i> : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditPopup;
