import React from "react";
import { useLocation } from "react-router-dom";
import AtsOverview from "src/containers/candidateOverview/AtsOverview";

function Candidateoverview() {
  const location = useLocation();
  location.header = "/ats/candidate-overview";
  return (
    <>
      <div>
        <div className="main-wrapper">
          <div className="main-body">{<AtsOverview />}</div>
        </div>
      </div>
    </>
  );
}

export default Candidateoverview;
