import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "src/components/employeeOverview/employeeOverview.css";
import { deleteUser, getAllUser } from "src/services/adminPanel/adminPanel";
import { notificationError, notificationSuccess } from "src/utils/utils";
import back from "src/assets/images/back.svg";
import edit from "src/assets/images/edit.svg";
import deleteIcon from "src/assets/images/delete.svg";
import EditPopup from "src/components/popup/adminPanelPopup/EditPopup";

const AdminPanel = () => {
  const [candidateList, setCandidateList] = useState([]);
  const [selectedData, setSelectedData] = useState("");
  const [openAddUserPopup, setOpenAddUserPopup] = useState(false);
  //For TableHeading
  const tableHeadings = [
    { width: "35%", name: "Name" },
    { width: "40%", name: "Email Id" },
    { width: "35%", name: "Role" },
    { width: "7%", name: "" }
  ];

  const handlePopUp = () => {
    setOpenAddUserPopup(false);
    setSelectedData(null);
  };
  const getAllCandidatesData = async () => {
    const response = await getAllUser({
      pageNumber: 1,
      pageSize: 100
    });
    setCandidateList(response.data?.data?.user);
  };
  useEffect(() => {
    getAllCandidatesData();
  }, []);

  const deleteCandidate = async (id) => {
    const response = await deleteUser({
      _id: id
    });
    if (response.data?.code === 200) {
      notificationSuccess(response.data?.message);
      getAllCandidatesData();
    } else {
      notificationError(response.data?.message ?? "Something went wrong");
    }
  };

  return (
    <div className="viewemployee-section">
      <div className="heading-section">
        <div className="h-title">
          <Link className="back-to" to="/ats/candidate-overview">
            <img src={back} alt="back" />
          </Link>
          <h4>User Role</h4>
        </div>
        <div className="search">
          <button className="btn-blue" onClick={() => setOpenAddUserPopup(true)}>
            Add User
          </button>
        </div>
      </div>
      <div className="table-view">
        <table className="table" style={{ textTransform: "none" }}>
          <thead>
            <tr>
              {tableHeadings.map((ele, index) => {
                return (
                  <th style={{ width: `${ele.width}` }} key={index}>
                    {ele.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <>
              {candidateList?.length > 0
                ? candidateList.map((candidate, ind) => {
                    return (
                      <tr key={ind} style={{ position: "relative" }}>
                        <td className="ellipsis">{candidate.name}</td>
                        <td>
                          <span>{candidate.email}</span>
                        </td>
                        <td>{candidate.role}</td>

                        <td>
                          <ul className="table-action">
                            <li>
                              <a>
                                <img
                                  onClick={() => {
                                    setOpenAddUserPopup(true);
                                    setSelectedData(candidate);
                                  }}
                                  src={edit}
                                  alt=""
                                />
                              </a>
                            </li>
                            <li onClick={() => deleteCandidate(candidate._id)}>
                              <a>
                                <img src={deleteIcon} alt="Delete_Icon" />
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    );
                  })
                : ""}
            </>
          </tbody>
        </table>
        {openAddUserPopup ? (
          <EditPopup
            close={handlePopUp}
            selectedCandidate={selectedData}
            setRender={() => getAllCandidatesData()}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default AdminPanel;
