import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import "src/components/popup/popup.css";
import "src/containers/addCandidate/AddCandidate.css";
import back from "src/assets/images/close icon.svg";
import crossImg from "src/assets/images/x.svg";
import { useDispatch } from "react-redux";
import { editCandidatePutApi } from "src/redux/candidateOverview/action";

import Select from "react-select";
import {
  candidateSkillSetsArr,
  candidateStatusArr,
  extraSkills,
  interviewedByArr,
  SourceArr
} from "src/utils/uiConstants";
import Editor from "src/components/quillEditor/index";

const newOptions = extraSkills.map((item) => {
  return { label: item, value: item };
});

function Editcandidate({ candidate, id, close, setRender }) {
  const dispatch = useDispatch();
  const refers = useRef([]);
  const [isLoadBtn, setIsLoadBtn] = useState(false);
  const [experienceField, setExperienceField] = useState(candidate.experienceInfo);
  const [educationField, setEducationField] = useState([]);
  const [resume, setResume] = useState(candidate.resume || []);
  const [resumeErr, setResumeErr] = useState(null);
  const [showAddress, setShowAddress] = useState(false);
  const [addressCheck, setAddressCheck] = useState(false);
  const [offerLetterLoader, setOfferLetterLoader] = useState(candidate.otherOffers);
  const [skillSet, setskillSet] = useState(candidate.skillSet || null);
  const [skillSetErr, setskillSetErr] = useState(null);
  const [otherOfferValueErr, setOtherOfferValueErr] = useState(null);
  const [validationFormCheck, setValidationFormCheck] = useState(false);
  const [data, setData] = useState({
    firstName: candidate.firstName,
    lastName: candidate.lastName,
    status: candidate.status,
    phoneNumber: candidate.phoneNumber,
    email: candidate.email,
    currentAddress: candidate.currentAddress,
    permanentAddress: candidate.permanentAddress,
    currentJobTitle: candidate.currentJobTitle,
    totalExperience: candidate.totalExperience,
    currentSalaryPerYear: candidate.currentSalaryPerYear,
    currentSalaryPerMonth: candidate.currentSalaryPerMonth,
    expectedSalaryPerYear: candidate.expectedSalaryPerYear,
    expectedSalaryPerMonth: candidate.expectedSalaryPerMonth,
    noticePeriod: candidate.noticePeriod,
    interviewBy: candidate.interviewBy,
    relevantExperience: candidate.relevantExperience,
    expectedJoiningDate: candidate.expectedJoiningDate,
    servedNoticePeriod: candidate.servedNoticePeriod,
    remarks: candidate.remarks,
    source: candidate.source,
    totalMonthOfExperience: candidate.totalMonthOfExperience,
    totalMonthOfRelevantExperience: candidate.totalMonthOfRelevantExperience,
    offeredValue: candidate.offeredValue,
    isNegotiable: candidate.isNegotiable,
    _id: id
  });

  useEffect(() => {
    refers.current.map((item) => (item.message = ""));
    if (candidate.currentAddress || candidate.permanentAddress) {
      setShowAddress(true);
    }
    if (
      candidate.permanentAddress === candidate.currentAddress &&
      candidate.permanentAddress !== ""
    ) {
      setAddressCheck(true);
    }
    setOfferLetterLoader(candidate.otherOffers);
  }, []);

  const formDetailsData = async () => {
    setIsLoadBtn(true);
    if (resume.length === 0) {
      setResumeErr("Please Upload Resume");
      setIsLoadBtn(false);
    }
    if (skillSet.length <= 0) {
      setskillSetErr("Please Enter Skill Set");
      setIsLoadBtn(false);
    }
    if (offerLetterLoader === "true" && data.offeredValue === "") {
      setOtherOfferValueErr("Please Enter Offered Value");
      setIsLoadBtn(false);
    }
    const validateForm = refers.current.every((item) => item?.value);

    const correctFieldValidation = refers.current.every(
      (item) => item?.message === ""
    );
    setValidationFormCheck(!validateForm);

    if (!validateForm) {
      setTimeout(() => {
        setIsLoadBtn(false);
      }, 400);
      return;
    }

    if (!correctFieldValidation) {
      setTimeout(() => {
        setIsLoadBtn(false);
      }, 400);
      return;
    }
    if (skillSetErr) {
      setIsLoadBtn(false);
      setskillSetErr("SkillS are Required");
    } else if (resumeErr) {
      setResumeErr("Please Upload Resume");
      setIsLoadBtn(false);
    } else if (offerLetterLoader === "true" && data.offeredValue === "") {
      setOtherOfferValueErr("Please Enter Offered Value");
      setIsLoadBtn(false);
    } else {
      let formData = new FormData();
      for (let key in data) {
        if (key === "expectedJoiningDate" && data[key] === null) {
          continue;
        }
        formData.append(key, data[key]);
      }
      if (typeof resume === "string") {
        formData.delete("resume");
      } else {
        formData.append("resume", resume);
      }
      formData.append("skillSet", JSON.stringify(skillSet));
      formData.delete("otherOffers");

      formData.append("otherOffers", offerLetterLoader);
      const temp = educationField.filter((item) => item.school || item.degree);
      formData.append("educationInfo", JSON.stringify(temp));
      const temp2 = experienceField.filter((item) => item.company || item.jobTitle);
      formData.append("experienceInfo", JSON.stringify(temp2));

      const res = await dispatch(editCandidatePutApi(formData));
      if (res.data.code === 200) {
        close();
        setRender();
        setIsLoadBtn(false);
      } else {
        setIsLoadBtn(false);
      }
    }
  };

  const handleNegotiatable = (e) => {
    setData({ ...data, [e.target.name]: e.target.checked });
  };
  //FormData onChange
  const onInputChangeHandler = (e, index = 1, name = null) => {
    if (name) {
      setData({ ...data, [name]: e });
      return;
    }
    const letter = e.target.value.trim();
    if (e.target.type === "servedNoticePeriod") {
      setData({ ...data, [e.target.name]: e.target.checked });
    } else if (e.target.name === "firstName" || e.target.name === "lastName") {
      if (e.target.value.match(/^[A-Za-z\s]*$/)) {
        setData({
          ...data,
          [e.target.name]: letter.charAt(0).toUpperCase() + letter.slice(1)
        });
        refers.current[index].message = "";
      } else {
        setData({ ...data, [e.target.name]: letter });
        refers.current[index].message = `Invalid  ${e.target.name}`;
      }
    } else if (e.target.name === "phoneNumber") {
      if (e.target.value.match(/^[6789]\d{9}$/)) {
        setData({ ...data, [e.target.name]: letter });
        refers.current[index].message = "";
      } else {
        setData({ ...data, [e.target.name]: letter });
        if (e.target.value.length > 9) {
          refers.current[index].message = ` Invalid Phone Number`;
        } else {
          refers.current[index].message = ` Phone Number must contain 10 digits`;
        }
      }
    } else if (e.target.name === "email") {
      if (e.target.value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
        setData({ ...data, [e.target.name]: letter });
        refers.current[index].message = "";
      } else {
        setData({ ...data, [e.target.name]: letter });
        if (!e.target.value.match("@")) {
          refers.current[index].message = `Please Enter Email Address Including @`;
        } else {
          refers.current[index].message = `Invalid Email Address`;
        }
      }
    } else if (
      e.target.name === "currentSalaryPerYear" ||
      e.target.name === "currentSalaryPerMonth" ||
      e.target.name === "expectedSalaryPerYear" ||
      e.target.name === "expectedSalaryPerMonth"
    ) {
      const arrValidate = e.target.value.split("");
      if (arrValidate.includes(".") || e.nativeEvent.data === "-") {
        e.preventDefault();
      } else {
        if (e.target.name === "currentSalaryPerMonth") {
          refers.current[index].message = "";
          setData({
            ...data,
            currentSalaryPerYear: parseInt(e.target.value * 12).toString(),
            [e.target.name]: e.target.value
          });
          refers.current[index - 1].message = "";
        } else if (e.target.name === "currentSalaryPerYear") {
          refers.current[index].message = "";
          setData({
            ...data,
            currentSalaryPerMonth: parseInt(e.target.value / 12).toString(),
            [e.target.name]: e.target.value
          });
          refers.current[index + 1].message = "";
        } else if (e.target.name === "expectedSalaryPerMonth") {
          refers.current[index].message = "";
          setData({
            ...data,
            expectedSalaryPerYear: parseInt(e.target.value * 12).toString(),
            [e.target.name]: e.target.value
          });
          refers.current[index - 1].message = "";
        } else if (e.target.name === "expectedSalaryPerYear") {
          refers.current[index].message = "";
          setData({
            ...data,
            expectedSalaryPerMonth: parseInt(e.target.value / 12).toString(),
            [e.target.name]: e.target.value
          });
          refers.current[index + 1].message = "";
        }
      }
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  //Resume
  const resumeHandler = (e) => {
    setResume(e.target.files[0]);
    setResumeErr(null);
  };

  const handleAddessChecked = (e) => {
    setAddressCheck(e.target.checked);
    if (e.target.checked) {
      setData({ ...data, permanentAddress: data.currentAddress });
    }
  };

  //Education Field
  const onEducationHandleChange = (index, e) => {
    let qus = JSON.parse(JSON.stringify(educationField));
    let educationlData = qus.map((obj, ind) => {
      if (index === ind) {
        obj[e.target.name] = e.target.value;
        return obj;
      } else {
        return obj;
      }
    });
    setEducationField(educationlData);
  };

  const addEducationFields = () => {
    let newfield = { school: "", degree: "", startDate1: "", endDate1: "" };
    setEducationField([...educationField, newfield]);
  };

  function removeEducationFields(i) {
    let values = [...educationField];
    values.splice(i, 1);
    setEducationField(values);
  }

  //Experience Field
  const addExperienceFields = () => {
    let newfiel = { jobTitle: "", company: "", exstartDate: "", exendDate: "" };
    setExperienceField([...experienceField, newfiel]);
  };
  const onExperienceHandleChange = (index, event) => {
    let qus = JSON.parse(JSON.stringify(experienceField));
    let educationlData = qus.map((obj, ind) => {
      if (index === ind) {
        obj[event.target.name] = event.target.value;
      }
      return obj;
    });
    setExperienceField(educationlData);
  };

  function removeExperienceField(i) {
    let values = [...experienceField];
    values.splice(i, 1);
    setExperienceField(values);
  }
  useEffect(() => {
    const temp = [];
    if (candidate.educationInfo) {
      for (let i = 0; i < candidate.educationInfo.length; i++) {
        temp.push(Object.assign(candidate.educationInfo[i]));
      }
      setEducationField(temp);
    }
  }, []);
  return (
    <>
      <div className="statusModal">
        <div>
          <div className="hrModal">
            <div className="statusHead">
              <h4>Document Details</h4>
              <img width={30} src={back} onClick={close} alt="back" />
            </div>
            <div className="statusForm">
              <div className="input-group mmt-10">
                <label className="">
                  Resume Upload <span className="required_label">*</span>
                </label>
                {resume.length ? (
                  <div className="view__cross__document">
                    <a href={resume} target={"_blank"} rel="noreferrer">
                      View
                    </a>{" "}
                    <img
                      src={crossImg}
                      width={"20"}
                      alt="cross"
                      onClick={() => setResume([])}
                    />
                  </div>
                ) : (
                  <>
                    <input
                      accept=".pdf"
                      type="file"
                      name="resume"
                      onChange={resumeHandler}
                    />
                    {resumeErr && (
                      <span className="required__error__message">{resumeErr}</span>
                    )}
                  </>
                )}
              </div>

              <div className="input-group mmt-5">
                <div className="same">
                  <div className="w-48">
                    <label>Other Offers</label>
                    <select
                      className="input-form"
                      onChange={(e) => {
                        setOfferLetterLoader(e.target.value);
                        if (e.target.value === "false") {
                          setData({ ...data, offeredValue: "" });
                          setOtherOfferValueErr(null);
                        }
                      }}
                      value={offerLetterLoader}
                    >
                      <option hidden value="">
                        Please Select
                      </option>
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    {otherOfferValueErr && (
                      <span className="required__error__message">
                        {otherOfferValueErr}
                      </span>
                    )}
                  </div>
                  <div>
                    {offerLetterLoader === "true" && (
                      <>
                        <label>
                          Offered Value In LPA
                          <span className="required_label"> *</span>
                        </label>
                        <input
                          className="input-form"
                          autoComplete="off"
                          placeholder="In Lakhs"
                          min="0"
                          onWheel={(e) => e.target.blur()}
                          onKeyDown={(e) =>
                            ["e", "E", "+", "-"].includes(e.key) &&
                            e.preventDefault()
                          }
                          type="number"
                          step={"0.1"}
                          accept=""
                          value={data.offeredValue}
                          onChange={(e) => {
                            onInputChangeHandler(e);
                            setOtherOfferValueErr(null);
                          }}
                          name="offeredValue"
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="statusHead">
              <h4>Basic Details</h4>
            </div>
            <div className="statusForm">
              <div className="input-group">
                <label>
                  First Name <span className="required_label">*</span>
                </label>
                <input
                  autoComplete="off"
                  className="input-form "
                  type="text"
                  value={data.firstName}
                  id="candidate_firstName"
                  ref={(element) => (refers.current[0] = element)}
                  name="firstName"
                  onChange={(e) => onInputChangeHandler(e, 0)}
                  required={validationFormCheck}
                />
                {validationFormCheck && !data.firstName.length ? (
                  <span className="required__error__message">
                    Please Enter First Name
                  </span>
                ) : data.firstName.length ? (
                  <span className="required__error__message">
                    {refers?.current[0]?.message}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="input-group">
                <label>
                  Last Name <span className="required_label">*</span>
                </label>
                <input
                  className="input-form"
                  autoComplete="off"
                  type="text"
                  value={data.lastName}
                  ref={(element) => (refers.current[1] = element)}
                  name="lastName"
                  onChange={(e) => onInputChangeHandler(e, 1)}
                  required={validationFormCheck}
                />
                {validationFormCheck && !data.lastName.length ? (
                  <span className="required__error__message">
                    Please Enter Last Name
                  </span>
                ) : data.lastName.length ? (
                  <span className="required__error__message">
                    {refers?.current[1]?.message}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="input-group">
                <label>
                  Phone <span className="required_label">*</span>
                </label>
                <input
                  className="input-form"
                  autoComplete="off"
                  type="number"
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                  }
                  value={data.phoneNumber}
                  ref={(element) => (refers.current[2] = element)}
                  name="phoneNumber"
                  onChange={(e) => onInputChangeHandler(e, 2)}
                  required={validationFormCheck}
                />
                {validationFormCheck && !data.phoneNumber ? (
                  <span className="required__error__message">
                    Please Enter Phone Number
                  </span>
                ) : data.phoneNumber.length ? (
                  <span className="required__error__message">
                    {refers?.current[2]?.message}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="input-group">
                <label>
                  Email <span className="required_label">*</span>
                </label>
                <input
                  className="input-form"
                  autoComplete="off"
                  type="mail"
                  value={data.email}
                  ref={(element) => (refers.current[3] = element)}
                  name="email"
                  onChange={(e) => onInputChangeHandler(e, 3)}
                  required={validationFormCheck}
                />
                {validationFormCheck && !data.email.length ? (
                  <span className="required__error__message">
                    Please Enter Email
                  </span>
                ) : data.email.length ? (
                  <span className="required__error__message">
                    {refers?.current[3]?.message}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="statusHead">
              <h4>Professional Details</h4>
            </div>
            <div className="statusForm">
              <div className="tt-exp">
                <label>
                  Total Experience <span className="required_label">*</span>
                </label>

                <div className="tt-exp2">
                  <div className="input-group mmt-5">
                    <label>Years</label>
                    <input
                      type="number"
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                      }
                      min="0"
                      name="totalExperience"
                      step="any"
                      id=""
                      onWheel={(e) => e.target.blur()}
                      value={data.totalExperience}
                      ref={(element) => (refers.current[5] = element)}
                      onChange={(e) => onInputChangeHandler(e, 5)}
                      className="input-form"
                      autoComplete="off"
                      required={validationFormCheck}
                    />
                  </div>
                  <div className="input-group mmt-5">
                    <label>Months</label>
                    <select
                      name="totalMonthOfExperience"
                      onChange={onInputChangeHandler}
                      value={data.totalMonthOfExperience}
                    >
                      <option hidden value="">
                        Please Select
                      </option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                    </select>
                  </div>
                </div>
                {validationFormCheck && !data.totalExperience.length ? (
                  <span className="required__error__message">
                    Please Enter Total Experience
                  </span>
                ) : data.totalExperience.length ? (
                  <span className="required__error__message">
                    {refers?.current[5]?.message}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="tt-exp">
                <label>
                  Relevant Experience <span className="required_label">*</span>
                </label>
                <div className="tt-exp2">
                  <div className="input-group mmt-5">
                    <label>Years</label>
                    <input
                      className="input-form"
                      autoComplete="off"
                      value={data.relevantExperience}
                      name="relevantExperience"
                      onWheel={(e) => e.target.blur()}
                      ref={(element) => (refers.current[6] = element)}
                      onChange={(e) => onInputChangeHandler(e, 6)}
                      type="number"
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                      }
                      min="0"
                      required={validationFormCheck}
                    />
                  </div>
                  <div className="input-group mmt-5">
                    <label>Months</label>
                    <select
                      name="totalMonthOfRelevantExperience"
                      onChange={onInputChangeHandler}
                      value={data.totalMonthOfRelevantExperience}
                    >
                      <option hidden value="">
                        Please Select
                      </option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                    </select>
                  </div>
                </div>
                {validationFormCheck && !data.relevantExperience.length ? (
                  <span className="required__error__message">
                    Please Enter Relevant Experience
                  </span>
                ) : data.relevantExperience.length ? (
                  <span className="required__error__message">
                    {refers?.current[6]?.message}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="input-group">
                <label>
                  Current Salary Per Year <span className="required_label">*</span>
                </label>
                <input
                  className="input-form"
                  autoComplete="off"
                  value={data.currentSalaryPerYear}
                  name="currentSalaryPerYear"
                  ref={(element) => (refers.current[7] = element)}
                  onChange={(e) => onInputChangeHandler(e, 7)}
                  type="number"
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                  }
                  required={validationFormCheck}
                />
                {validationFormCheck && !data.currentSalaryPerYear.length ? (
                  <span className="required__error__message">
                    Please Enter Current Salary Per Year
                  </span>
                ) : data.currentSalaryPerYear.length ? (
                  <span className="required__error__message">
                    {refers?.current[7]?.message}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="input-group">
                <label>
                  Current Salary Per Month <span className="required_label">*</span>
                </label>
                <input
                  className="input-form"
                  autoComplete="off"
                  value={data.currentSalaryPerMonth}
                  name="currentSalaryPerMonth"
                  ref={(element) => (refers.current[8] = element)}
                  onChange={(e) => onInputChangeHandler(e, 8)}
                  type="number"
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                  }
                  required={validationFormCheck}
                />
                {validationFormCheck && !data.currentSalaryPerMonth.length ? (
                  <span className="required__error__message">
                    Please Enter Current Salary Per Month
                  </span>
                ) : data.currentSalaryPerMonth.length ? (
                  <span className="required__error__message">
                    {refers?.current[8]?.message}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="input-group">
                <label>
                  Expected Salary Per year <span className="required_label">*</span>
                </label>
                <input
                  className="input-form"
                  autoComplete="off"
                  value={data.expectedSalaryPerYear}
                  name="expectedSalaryPerYear"
                  ref={(element) => (refers.current[9] = element)}
                  onChange={(e) => onInputChangeHandler(e, 9)}
                  type="number"
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                  }
                  required={validationFormCheck}
                />
                {validationFormCheck && !data.expectedSalaryPerYear.length ? (
                  <span className="required__error__message">
                    Please Enter Expected Salary Per Year
                  </span>
                ) : data.expectedSalaryPerYear.length ? (
                  <span className="required__error__message">
                    {refers?.current[9]?.message}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="input-group">
                <label>
                  Expected Salary Per Month <span className="required_label">*</span>
                </label>
                <input
                  className="input-form"
                  autoComplete="off"
                  value={data.expectedSalaryPerMonth}
                  name="expectedSalaryPerMonth"
                  ref={(element) => (refers.current[10] = element)}
                  onChange={(e) => onInputChangeHandler(e, 10)}
                  type="number"
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                  }
                  required={validationFormCheck}
                />
                {validationFormCheck && !data.expectedSalaryPerMonth.length ? (
                  <span className="required__error__message">
                    Please Enter Expected Salary Per Month
                  </span>
                ) : data.expectedSalaryPerMonth.length ? (
                  <span className="required__error__message">
                    {refers?.current[10]?.message}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="input-group">
                <label>
                  Applied Role <span className="required_label">*</span>
                </label>
                <select
                  className="input-form"
                  autoComplete="off"
                  type="text"
                  value={data.currentJobTitle}
                  name="currentJobTitle"
                  ref={(element) => (refers.current[4] = element)}
                  onChange={(e) => onInputChangeHandler(e, 4)}
                  required={validationFormCheck}
                >
                  <option hidden value="">
                    Please Select
                  </option>
                  {candidateSkillSetsArr.map((options, i) => (
                    <option key={i} value={options}>
                      {options}
                    </option>
                  ))}
                </select>
                {validationFormCheck && !data.currentJobTitle.length ? (
                  <span className="required__error__message">
                    Please Enter Current Job Title
                  </span>
                ) : data.currentJobTitle.length ? (
                  <span className="required__error__message">
                    {refers?.current[4]?.message}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="input-group">
                <label>
                  Skills <span className="required_label">*</span>
                </label>

                <Select
                  name="skillSet"
                  placeholder="Please Select"
                  id=""
                  value={skillSet}
                  onChange={(e) => {
                    setskillSet(e);
                    setskillSetErr(null);
                  }}
                  closeMenuOnSelect={false}
                  className={
                    skillSetErr
                      ? "multiSelectContainer err-border"
                      : "multiSelectContainer"
                  }
                  isMulti={true}
                  maxLength={2}
                  options={newOptions}
                />
                {skillSetErr && (
                  <span className="required__error__message">{skillSetErr}</span>
                )}
              </div>
              <div className="input-group">
                <label>
                  Notice Period <span className="required_label">*</span>
                </label>
                <select
                  name="noticePeriod"
                  value={data.noticePeriod}
                  onChange={(e) => onInputChangeHandler(e, 11)}
                  required={validationFormCheck}
                  ref={(element) => (refers.current[11] = element)}
                >
                  <option hidden value="">
                    Please Select
                  </option>
                  <option value="15 Days">15 Days</option>
                  <option value="30 Days">30 Days</option>
                  <option value="45 Days">45 Days</option>
                  <option value="60 Days">60 Days</option>
                  <option value="90 Days">90 Days</option>
                  <option value="Immediate joiner">Immediate joiner</option>
                </select>
                {validationFormCheck && !data.noticePeriod.length ? (
                  <span className="required__error__message">
                    Please Select Notice Period
                  </span>
                ) : (
                  ""
                )}
              </div>

              <div className="input-group">
                <label>Expected Joining Date</label>
                <input
                  type="date"
                  id=""
                  name="expectedJoiningDate"
                  onChange={onInputChangeHandler}
                  value={moment(data.expectedJoiningDate).format("YYYY-MM-DD")}
                  className="input-form"
                  autoComplete="off"
                  min={moment().format("YYYY-MM-DD")}
                />
              </div>

              <div className="flex">
                <div className="input-group">
                  <label>Serving Notice Period</label>
                  <select
                    id="servedNotice"
                    name="servedNoticePeriod"
                    onChange={onInputChangeHandler}
                    className="input-form"
                    autoComplete="off"
                    value={data.servedNoticePeriod}
                  >
                    <option hidden value="">
                      Please Select
                    </option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                </div>
                <div className="neg">
                  <input
                    name="isNegotiable"
                    type="checkbox"
                    onChange={handleNegotiatable}
                    checked={data.isNegotiable}
                  />
                  <span> NP Negotiatable</span>
                </div>
              </div>
            </div>

            <div className="statusHead">
              <h4>Address Details</h4>
              <p
                className="cursor-pointer"
                onClick={() => setShowAddress(!showAddress)}
              >
                {showAddress ? "Hide" : "Show"}
              </p>
            </div>
            {showAddress && (
              <div className="statusForm">
                <div className="input-group">
                  <label>Current Address </label>
                  <textarea
                    className="input-form"
                    autoComplete="off"
                    value={data.currentAddress}
                    onChange={onInputChangeHandler}
                    name="currentAddress"
                  ></textarea>
                </div>
                <div className="input-group">
                  <label>Permanent Address </label>
                  <textarea
                    className="input-form"
                    autoComplete="off"
                    value={
                      !addressCheck ? data.permanentAddress : data.currentAddress
                    }
                    onChange={onInputChangeHandler}
                    name="permanentAddress"
                  ></textarea>
                  <div className="neg">
                    <input
                      type="checkbox"
                      id="address"
                      name="address"
                      onChange={(e) => handleAddessChecked(e)}
                      checked={addressCheck}
                    />
                    <label htmlFor="address" className="mmt-5">
                      Same as Current Address
                    </label>
                  </div>
                </div>
              </div>
            )}
            <div className="statusHead">
              <h4>Education Details</h4>
              <p className="cursor-pointer" onClick={() => addEducationFields()}>
                + Add
              </p>
            </div>
            {educationField.map((items, idx) => {
              return (
                <div className="form-info" key={`${items}-${idx}`}>
                  <div className="statusForm">
                    <div className="input-group">
                      <label>School/Institute</label>
                      <input
                        autoComplete="off"
                        type="text"
                        name="school"
                        id=""
                        value={items.school}
                        onChange={(event) => onEducationHandleChange(idx, event)}
                        className="input-form"
                      />
                    </div>
                    <div className="input-group">
                      <label>Degree</label>

                      <select
                        name="degree"
                        id=""
                        value={items.degree}
                        onChange={(event) => onEducationHandleChange(idx, event)}
                        className="input-form"
                      >
                        <option hidden value="">
                          Please Select
                        </option>
                        <option value="10th">10th</option>
                        <option value="12th">12th</option>
                        <option value="Diploma">Diploma</option>
                        <option value="BE/B.Tech">BE/B.Tech</option>
                        <option value="M.Tech">M.Tech</option>
                        <option value="M.Sc">M.Sc</option>
                        <option value="MBA">MBA</option>
                        <option value="Degree">Degree</option>
                      </select>
                    </div>
                    <div className="input-group">
                      <label>Start Date</label>
                      <input
                        type="date"
                        name="startDate1"
                        id=""
                        value={items.startDate1}
                        onChange={(event) => onEducationHandleChange(idx, event)}
                        className="input-form"
                        max={moment().format("YYYY-MM-DD")}
                      />
                    </div>
                    <div className="input-group">
                      <label>End Date</label>
                      <input
                        type="date"
                        name="endDate1"
                        id=""
                        value={items.endDate1}
                        onChange={(event) => onEducationHandleChange(idx, event)}
                        className="input-form"
                        max={moment().format("YYYY-MM-DD")}
                      />
                    </div>
                    <div className="remove">
                      <button onClick={() => removeEducationFields(idx)}>X</button>
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="statusHead">
              <h4>Experience Details</h4>
              <p className="cursor-pointer" onClick={() => addExperienceFields()}>
                + Add
              </p>
            </div>

            {experienceField.map((fields, idx) => {
              return (
                <div className="form-info" key={`${fields}-${idx}`}>
                  <div className="statusForm">
                    <div className="input-group">
                      <label>JobTitle</label>
                      <input
                        autoComplete="off"
                        type="text"
                        name="jobTitle"
                        id=""
                        value={fields.jobTitle}
                        onChange={(event) => onExperienceHandleChange(idx, event)}
                        className="input-form"
                      />
                    </div>
                    <div className="input-group">
                      <label>Company</label>
                      <input
                        autoComplete="off"
                        type="text"
                        name="company"
                        id=""
                        value={fields.company}
                        onChange={(event) => onExperienceHandleChange(idx, event)}
                        className="input-form"
                      />
                    </div>
                    <div className="input-group">
                      <label>Start Date</label>
                      <input
                        type="date"
                        name="exstartDate"
                        id=""
                        value={fields.exstartDate}
                        onChange={(event) => onExperienceHandleChange(idx, event)}
                        className="input-form"
                        max={moment().format("YYYY-MM-DD")}
                      />
                    </div>
                    <div className="input-group">
                      <label>End Date</label>
                      <input
                        type="date"
                        name="exendDate"
                        id=""
                        value={fields.exendDate}
                        onChange={(event) => onExperienceHandleChange(idx, event)}
                        className="input-form"
                        max={moment().format("YYYY-MM-DD")}
                      />
                    </div>
                    <div className="remove">
                      <button onClick={() => removeExperienceField(idx)}>X</button>
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="statusHead">
              <h4>Other Details</h4>
            </div>
            <div className="statusForm">
              <div className="input-group">
                <label>Candidate Status</label>
                <select
                  disabled={true}
                  defaultValue={data.status}
                  onChange={(e) => onInputChangeHandler(e, 12)}
                  name="status"
                  required={validationFormCheck}
                  ref={(element) => (refers.current[12] = element)}
                >
                  <option hidden value="">
                    Please Select
                  </option>
                  {candidateStatusArr.map((opt, i) => (
                    <option key={i} value={opt}>
                      {opt}
                    </option>
                  ))}
                </select>
                {validationFormCheck && !data.status.length ? (
                  <span className="required__error__message">
                    Please Select Candidate Status
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="input-group">
                <label>
                  Source <span className="required_label">*</span>
                </label>
                <select
                  value={data.source}
                  onChange={(e) => onInputChangeHandler(e, 13)}
                  name="source"
                  required={validationFormCheck}
                  ref={(element) => (refers.current[13] = element)}
                >
                  <option hidden value="">
                    Please Select
                  </option>
                  {SourceArr.map((opt, i) => (
                    <option key={i} value={opt}>
                      {opt}
                    </option>
                  ))}
                </select>
                {validationFormCheck && !data.source.length ? (
                  <span className="required__error__message">
                    Please Select Source
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="input-group">
                <label>
                  Added By <span className="required_label">*</span>
                </label>
                <select
                  value={data.interviewBy}
                  onChange={(e) => onInputChangeHandler(e)}
                  name="interviewBy"
                  disabled={true}
                >
                  <option hidden value="">
                    {data.interviewBy}
                  </option>
                  {interviewedByArr.map((opt, i) => (
                    <option key={i} value={opt}>
                      {opt}
                    </option>
                  ))}
                </select>
              </div>
              <div className="remark-area">
                <label className="mmb-10">Notes</label>
                <Editor
                  value={data.remarks}
                  remarksUpdateHandler={(html) =>
                    onInputChangeHandler(html, 16, "remarks")
                  }
                  autoCapitalize="off"
                />
              </div>
            </div>

            <div className="btn-section">
              <button
                className="btn-blue"
                disabled={isLoadBtn}
                onClick={formDetailsData}
              >
                {isLoadBtn ? (
                  <i className="fa fa-circle-o-notch fa-spin"></i>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Editcandidate;
