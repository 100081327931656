import { isAuthenticated, notificationError } from "src/utils/utils";
import { candidateDashboardActions } from "src/redux/candidateDashboard/candidateDashboardSlice";
import {
  getDashboardOfferAcceptedReqApi,
  getDashboardReqApi,
  getDashboardStartEndDateReqApi,
  getDashboardTechReqApi
} from "src/services/candidateDashboard/candidateDashboard";

const { getLoader, getDashboardData, getOfferAccepted } = candidateDashboardActions;

export const getDashboard = () => async (dispatch) => {
  dispatch(getLoader());
  const response = await getDashboardReqApi();
  const userAuth = await isAuthenticated(response.data.message);
  if (userAuth) {
    if (response.data.status === true || response?.data?.code === 200) {
      dispatch(getDashboardData(response?.data?.data));
    } else {
      notificationError(response.data.message);
    }
    dispatch(getLoader());
  } else {
    notificationError(response.data.message);
    localStorage.clear();
    localStorage.setItem("userAuth", "false");
  }
};

export const getDashboardOfferAccepted = (tech) => async (dispatch) => {
  dispatch(getLoader());
  const response = await getDashboardOfferAcceptedReqApi(tech);
  const userAuth = await isAuthenticated(response.data.message);
  if (userAuth) {
    if (response.data.status === true || response?.data?.code === 200) {
      dispatch(getOfferAccepted(response?.data?.data));
    }
    dispatch(getLoader());
  } else {
    notificationError(response.data.message);
    localStorage.clear();
    localStorage.setItem("userAuth", "false");
  }
};

export const getDashboardTechData = (payload) => async (dispatch) => {
  const response = await getDashboardTechReqApi(payload);
  const userAuth = await isAuthenticated(response.data.message);
  if (userAuth) {
    if (response.data.status === true || response?.data?.code === 200) {
      dispatch(getDashboardData(response?.data?.data));
    } else {
      notificationError(response.data.message);
    }
    return response;
  } else {
    notificationError(response.data.message);
    localStorage.clear();
    localStorage.setItem("userAuth", "false");
  }
};

export const getDashboardStartEndData = (payload) => async (dispatch) => {
  dispatch(getLoader());
  const response = await getDashboardStartEndDateReqApi(payload);
  if (response.data.status === true || response?.data?.code === 200) {
    dispatch(getDashboardData(response?.data?.data));
  } else {
    notificationError(response.data.message);
  }
  dispatch(getLoader());
};
