import {
  isAuthenticated,
  notificationError,
  notificationSuccess
} from "src/utils/utils";

import { candidateOverviewActions } from "src/redux/candidateOverview/candidateOverviewSlice";
import {
  addCandidateReqApi,
  deleteStatus,
  duplicateCandidateCheckReqApi,
  editCandidateReqApi,
  getAllCandidates,
  getCandidateDetails,
  getFilterCandidateList,
  getPipelineDetails,
  getSearchCandidateList
} from "src/services/addCandidate/addCandidate";

const {
  getAllCandidateList,
  getLoader,
  setSearchCandidateList,
  getCandidateDetail,
  setPipelineDetails
} = candidateOverviewActions;

export const getCandidateOverviewList = (payload) => async (dispatch) => {
  const response = await getAllCandidates(payload);
  if (response.data.status === true && response?.data?.code === 200) {
    dispatch(getAllCandidateList(response?.data?.data));
  } else if (response?.data?.code === 200 && response?.data?.data?.length === 0) {
    notificationSuccess(response.data.message);
  } else {
    notificationError(response.data.message);
  }
  return response;
};

export const getSearchCandidateOverviewList = (payload) => async (dispatch) => {
  const response = await getSearchCandidateList(payload);
  const userAuth = await isAuthenticated(response.data.message);
  if (userAuth) {
    if (response.data.status === true || response?.data?.code === 200) {
      dispatch(setSearchCandidateList(response.data.data));
    } else {
      dispatch(setSearchCandidateList(response.data.data));
    }
    return response;
  } else {
    notificationError(response.data.message);
    localStorage.clear();
    localStorage.setItem("userAuth", JSON.stringify(userAuth));
  }
};
export const getFilterCandidateOverviewList = (payload) => async (dispatch) => {
  const response = await getFilterCandidateList(payload);
  const userAuth = await isAuthenticated(response.data?.message);
  if (userAuth) {
    if (response.data.status === true || response?.data?.code === 200) {
      dispatch(setSearchCandidateList(response.data.data));
    } else {
      dispatch(setSearchCandidateList(response.data.data));
    }
    return response;
  } else {
    notificationError(response.data.message);
    localStorage.clear();
    localStorage.setItem("userAuth", JSON.stringify(userAuth));
  }
};

export const getSingleCandidateDetails = (payload) => async (dispatch) => {
  await dispatch(getLoader());
  const response = await getCandidateDetails(payload);
  const userAuth = await isAuthenticated(response.data.message);
  if (userAuth) {
    if (response?.data?.code === 200) {
      dispatch(getCandidateDetail(response.data.data));
    } else {
      notificationError(response?.data.message);
    }
    await dispatch(getLoader());
  } else {
    await notificationError(response.data.message);
    await localStorage.setItem("userAuth", "false");
  }
};

export const getAllPipelineDetails = (payload) => async (dispatch) => {
  const response = await getPipelineDetails(payload);
  const userAuth = await isAuthenticated(response.data.message);
  if (userAuth) {
    if (response.data.status === true || response?.data?.code === 200) {
      dispatch(setPipelineDetails(response.data.data));
    } else {
      notificationError(response.data.message);
    }
  } else {
    notificationError(response.data.message);
    localStorage.clear();
    localStorage.setItem("userAuth", JSON.stringify(userAuth));
  }
};

export const addCandidatePostApi = (payload) => async () => {
  const response = await addCandidateReqApi(payload);
  if (response.data.status === true || response?.data?.code === 200) {
    notificationSuccess(response?.data?.message);
  } else {
    notificationError(response.data.message);
  }
  return response;
};

export const duplicateCandidateCheck =
  (payload, msg = null) =>
  async () => {
    const response = await duplicateCandidateCheckReqApi(payload);
    if (response?.data?.status === true || response?.data?.code === 200) {
      if (msg) {
        notificationSuccess(msg);
      } else {
        notificationSuccess(response?.data?.message);
      }
    } else {
      notificationError(response?.data?.message);
    }
    return response;
  };

export const editCandidatePutApi = (payload) => async () => {
  const response = await editCandidateReqApi(payload);
  if (response.data.status === true || response?.data?.code === 200) {
    notificationSuccess(response?.data?.message);
  } else {
    notificationError(response.data.message);
  }
  return response;
};

export const deletCandidateStatus = (payload) => async () => {
  const response = await deleteStatus(payload);
  if (response.data.status === true || response?.data?.code === 200) {
    notificationSuccess(response.data.message);
  } else {
    notificationError(response.data.message);
  }
  return response;
};
