import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "src/containers/addCandidate/AddCandidate.css";
import back from "src/assets/images/back.svg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import { useRef } from "react";
import {
  addCandidatePostApi,
  duplicateCandidateCheck
} from "src/redux/candidateOverview/action";
import moment from "moment";
import {
  candidateSkillSetsArr,
  extraSkills,
  SourceArr
} from "src/utils/uiConstants";
import ExistingCandidateModal from "src/components/popup/ExistingCandidate/index";

import check from "src/assets/images/check.svg";
import Editor from "src/components/quillEditor/index";

const newOptions = extraSkills.map((item) => {
  return { label: item, value: item };
});

function Addcandidate() {
  const { name } = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  location.header = "/ats/candidate-overview";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const multiSelect = newOptions;
  const [educationField, setEducationField] = useState([]);
  const [loadButton, setLoadButton] = useState(false);
  const [experienceField, setExperienceField] = useState([]);
  const [resume, setResume] = useState("");
  const [resumeErr, setResumeErr] = useState(null);
  const [skillSet, setskillSet] = useState([]);
  const [skillSetErr, setskillSetErr] = useState(null);
  const [otherOfferValueErr, setOtherOfferValueErr] = useState(null);
  const [offerLetterLoader, setOfferLetterLoader] = useState("false");
  const [addressCheck, setAddressCheck] = useState(false);
  const [validationFormCheck, setValidationFormCheck] = useState(false);
  const refers = useRef([]);
  const [showAddress, setShowAddress] = useState(false);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    status: "Candidates",
    phoneNumber: "",
    email: "",
    currentAddress: "",
    permanentAddress: "",
    currentJobTitle: "",
    totalExperience: "",
    totalMonthOfExperience: "0",
    totalMonthOfRelevantExperience: "0",
    relevantExperience: "",
    currentSalaryPerYear: "",
    currentSalaryPerMonth: "",
    expectedSalaryPerYear: "",
    expectedSalaryPerMonth: "",
    otherOffers: "false",
    offeredValue: "",
    noticePeriod: "",
    expectedJoiningDate: "",
    servedNoticePeriod: false,
    remarks: "",
    source: "",
    interviewBy: name,
    isNegotiable: false
  });

  const [conflictUserData, setConflictUserData] = useState(null);
  const [isCandidateVerified, setCandidateVerified] = useState(false);
  useEffect(() => {
    refers.current.map((item) => (item.message = ""));
  }, []);

  const submitCandidateDetails = async () => {
    setLoadButton(true);
    if (skillSet.length <= 0) {
      setskillSetErr("Please Enter Skill Set");
      setLoadButton(false);
    }
    if (resume === "") {
      setResumeErr("Please Upload Resume");
      setLoadButton(false);
    }
    if (offerLetterLoader === "true" && data.offeredValue === "") {
      setOtherOfferValueErr("Please Enter Offered Value");
      setLoadButton(false);
    }
    const validateForm = refers.current.every((item) => item.value);
    const correctFieldValidation = refers.current.every(
      (item) => item?.message === ""
    );
    setValidationFormCheck(!validateForm);

    if (!validateForm) {
      setTimeout(() => {
        setLoadButton(false);
      }, 400);

      return;
    }

    if (!correctFieldValidation) {
      setTimeout(() => {
        setLoadButton(false);
      }, 400);
    }
    if (skillSetErr) {
      setLoadButton(false);
      setskillSetErr("SkillS are Required");
    } else if (resumeErr) {
      setResumeErr("Please Upload Resume");
      setLoadButton(false);
    } else if (offerLetterLoader === "true" && data.offeredValue === "") {
      setOtherOfferValueErr("Please Enter Offered Value");
      setLoadButton(false);
    } else {
      let formData = new FormData();
      for (let key in data) {
        if (key === "expectedJoiningDate" && data[key] === null) {
          continue;
        }
        formData.append(key, data[key]);
      }
      formData.append("resume", resume);
      formData.append("skillSet", JSON.stringify(skillSet));
      formData.delete("otherOffers");
      formData.append("otherOffers", offerLetterLoader);
      const temp = educationField.filter((item) => item.school || item.degree);
      formData.append("educationInfo", JSON.stringify(temp));
      const temp2 = experienceField.filter((item) => item.company || item.jobTitle);
      formData.append("experienceInfo", JSON.stringify(temp2));
      const res = await dispatch(addCandidatePostApi(formData));
      if (res.data.code === 200) {
        navigate("/ats/candidate-overview");
        setLoadButton(false);
        setCandidateVerified(false);
      } else {
        setLoadButton(false);
      }
    }
  };

  const handleNegotiatable = (e) => {
    setData({ ...data, [e.target.name]: e.target.checked });
  };
  const onInputChangeHandler = (e, index = 1, name = null) => {
    if (name) {
      setData({ ...data, [name]: e });
      return;
    }
    const letter = e?.target?.value?.trim();
    if (e.target.name === "servedNoticePeriod") {
      setData({ ...data, [e.target.name]: e.target.value });
    } else if (e.target.name === "firstName" || e.target.name === "lastName") {
      if (e.target.value.match(/^[A-Za-z\s]*$/)) {
        setData({
          ...data,
          [e.target.name]: letter.charAt(0).toUpperCase() + letter.slice(1)
        });
        refers.current[index].message = "";
      } else {
        setData({ ...data, [e.target.name]: letter });
        refers.current[index].message = `Invalid  ${e.target.name}`;
      }
    } else if (e.target.name === "phoneNumber") {
      if (e.target.value.match(/^[6789]\d{9}$/)) {
        setData({ ...data, [e.target.name]: letter });
        refers.current[index].message = "";
      } else {
        setData({ ...data, [e.target.name]: letter });
        if (e.target.value.length > 10) {
          refers.current[index].message = ` Invalid Phone Number`;
        } else {
          refers.current[index].message = ` Phone Number must contain 10 digits`;
        }
      }
    } else if (e.target.name === "email") {
      if (e.target.value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
        setData({ ...data, [e.target.name]: letter });
        refers.current[index].message = "";
      } else {
        setData({ ...data, [e.target.name]: letter });
        if (!e.target.value.match("@")) {
          refers.current[index].message = `Please Enter Email Address Including @`;
        } else {
          refers.current[index].message = `Invalid Email Address`;
        }
      }
    } else if (
      e.target.name === "currentSalaryPerYear" ||
      e.target.name === "currentSalaryPerMonth" ||
      e.target.name === "expectedSalaryPerYear" ||
      e.target.name === "expectedSalaryPerMonth"
    ) {
      const arrValidate = e.target.value.split("");
      if (arrValidate.includes(".") || e.nativeEvent.data === "-") {
        e.preventDefault();
      } else {
        if (e.target.name === "currentSalaryPerMonth") {
          refers.current[index].message = "";
          setData({
            ...data,
            currentSalaryPerYear: parseInt(e.target.value * 12).toString(),
            [e.target.name]: e.target.value
          });
          refers.current[index - 1].message = "";
        } else if (e.target.name === "currentSalaryPerYear") {
          refers.current[index].message = "";
          setData({
            ...data,
            currentSalaryPerMonth: parseInt(letter / 12).toString(),
            [e.target.name]: letter
          });
          refers.current[index + 1].message = "";
        } else if (e.target.name === "expectedSalaryPerMonth") {
          refers.current[index].message = "";
          setData({
            ...data,
            expectedSalaryPerYear: parseInt(letter * 12).toString(),
            [e.target.name]: letter
          });
          refers.current[index - 1].message = "";
        } else if (e.target.name === "expectedSalaryPerYear") {
          refers.current[index].message = "";
          setData({
            ...data,
            expectedSalaryPerMonth: parseInt(letter / 12).toString(),
            [e.target.name]: letter
          });
          refers.current[index + 1].message = "";
        } else if (
          e.target.name === "totalExperience" ||
          e.target.name === "relevantExperience"
        ) {
          refers.current[index].message = "";
          setData({
            ...data,
            expectedSalaryPerMonth: parseInt(letter / 12).toString(),
            [e.target.name]: letter
          });
          refers.current[index + 1].message = "";
        }
      }
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
      // refers.current[index].message = "";
    }
  };

  const resumeHandler = (e) => {
    setResume(e.target.files[0]);
    setResumeErr(null);
  };

  //Education Field

  const onEducationHandleChange = (index, e) => {
    let qus = [...educationField];
    let educationlData = qus.map((obj, ind) => {
      if (index === ind) {
        obj[e.target.name] = e.target.value;
      }
      return obj;
    });
    setEducationField(educationlData);
  };
  const addEducationFields = () => {
    let newField = { school: "", degree: "", startDate1: "", endDate1: "" };
    setEducationField([...educationField, newField]);
  };
  function removeEducationFields(i) {
    let values = [...educationField];
    values.splice(i, 1);
    setEducationField(values);
  }
  const addExperienceFields = () => {
    let newFiel = { jobTitle: "", company: "", exstartDate: "", exendDate: "" };
    setExperienceField([...experienceField, newFiel]);
  };
  const onExperienceHandleChange = (index, event) => {
    let qus = [...experienceField];
    let educationlData = qus.map((obj, ind) => {
      if (index === ind) {
        obj[event.target.name] = event.target.value;
      }
      return obj;
    });
    setExperienceField(educationlData);
  };

  function removeExperienceField(i) {
    let values = [...experienceField];
    values.splice(i, 1);
    setExperienceField(values);
  }

  const handleAddessChecked = (e) => {
    setAddressCheck(e.target.checked);
    if (e.target.checked) {
      setData({ ...data, permanentAddress: data.currentAddress });
    }
  };

  const verifyMobileAndEmail = async () => {
    let formData = new FormData();
    formData.append("email", data["email"]);
    formData.append("phoneNumber", data["phoneNumber"]);

    const res = await dispatch(duplicateCandidateCheck(formData));

    if (res.data.code === 200) {
      setCandidateVerified(true);
    } else {
      if (res.data.code === 409) {
        setConflictUserData(res?.data?.data);
        setCandidateVerified(false);
      }
    }
  };

  return (
    <>
      <div>
        <div className="main-wrapper">
          <div className="main-body">
            <div className="header-main">
              <div className="h-heading">
                <div className="h-title">
                  <Link className="back-to" to="/ats/candidate-overview">
                    <img src={back} alt="back" />
                  </Link>
                  <h4>Add Candidate</h4>
                </div>
              </div>
            </div>
            <div>
              <div className="white-wrapper">
                <div className="onboard-form candidate-form">
                  <div className="subh-heading">
                    <h4>Upload Resume</h4>
                  </div>
                  <div className="form-wrapper">
                    <div className="input-group">
                      <label>
                        Resume <span className="required_label">*</span>
                      </label>
                      <input
                        autoComplete="off"
                        type="file"
                        onChange={resumeHandler}
                        name="resume"
                        accept={".pdf"}
                      />
                      {resumeErr && (
                        <span className="required__error__message">{resumeErr}</span>
                      )}
                    </div>
                  </div>
                  {/* Basic Details */}
                  <div className="subh-heading">
                    <h4>Basic Details</h4>
                    <div className="verify-section" onClick={verifyMobileAndEmail}>
                      <p>check duplicates</p>
                      {isCandidateVerified && (
                        <img src={check} alt="" width={24} height={24} />
                      )}
                    </div>
                  </div>
                  <div className="form-wrapper input-group-over-write">
                    <div className="input-group ">
                      <div className="flex">
                        <div className="input-group hh-50">
                          <label>
                            First Name <span className="required_label">*</span>
                          </label>
                          <input
                            autoComplete="off"
                            type="text"
                            name="firstName"
                            value={data.firstName}
                            ref={(element) => (refers.current[0] = element)}
                            onChange={(e) => onInputChangeHandler(e, 0)}
                            id=""
                            className="input-form"
                            required={validationFormCheck}
                          />
                          {validationFormCheck && !data.firstName.length ? (
                            <span className="required__error__message">
                              Please Enter First Name
                            </span>
                          ) : data.firstName.length ? (
                            <span className="required__error__message">
                              {refers?.current[0]?.message}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="input-group">
                          <label>
                            Last Name <span className="required_label">*</span>
                          </label>
                          <input
                            autoComplete="off"
                            type="text"
                            name="lastName"
                            ref={(element) => (refers.current[1] = element)}
                            id=""
                            value={data.lastName}
                            onChange={(e) => onInputChangeHandler(e, 1)}
                            className="input-form"
                            required={validationFormCheck}
                          />
                          {validationFormCheck && !data.lastName.length ? (
                            <span className="required__error__message">
                              Please Enter Last Name
                            </span>
                          ) : data.lastName.length ? (
                            <span className="required__error__message">
                              {refers?.current[1]?.message}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="input-group">
                      <div className="flex">
                        <div className="input-group hh-50">
                          <label>
                            Phone <span className="required_label">*</span>
                          </label>
                          <input
                            autoComplete="off"
                            type="number"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            min="0"
                            onWheel={(e) => e.target.blur()}
                            name="phoneNumber"
                            id=""
                            ref={(element) => (refers.current[2] = element)}
                            value={data.phoneNumber}
                            onChange={(e) => onInputChangeHandler(e, 2)}
                            className="input-form"
                            required={validationFormCheck}
                          />
                          {validationFormCheck && !data.phoneNumber.length ? (
                            <span className="required__error__message">
                              Please Enter Phone Number
                            </span>
                          ) : data.phoneNumber.length ? (
                            <span className="required__error__message">
                              {refers?.current[2]?.message}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="input-group">
                          <label>
                            Email <span className="required_label">*</span>
                          </label>
                          <input
                            autoComplete="off"
                            type="email"
                            name="email"
                            id="email"
                            value={data.email}
                            ref={(element) => (refers.current[3] = element)}
                            onChange={(e) => onInputChangeHandler(e, 3)}
                            className="input-form"
                            required={validationFormCheck}
                          />
                          {validationFormCheck && !data.email.length ? (
                            <span className="required__error__message">
                              Please Enter Email
                            </span>
                          ) : data.email.length ? (
                            <span className="required__error__message">
                              {refers?.current[3]?.message}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Professional Details */}
                  <div className="subh-heading">
                    <h4>Professional Details</h4>
                  </div>
                  <div className="form-wrapper">
                    <div className="input-group input_level1Group">
                      <div className="flex">
                        <div className="input-group2">
                          <div className="input-group w-100">
                            <label>
                              Total Experience{" "}
                              <span className="required_label">*</span>
                            </label>
                            <div className="flex">
                              <div className="w-48">
                                <label>Years</label>
                                <select
                                  name="totalExperience"
                                  onChange={(e) => onInputChangeHandler(e, 5)}
                                  value={data.totalExperience}
                                  ref={(element) => (refers.current[5] = element)}
                                >
                                  <option hidden value="">
                                    Please Select
                                  </option>
                                  <option value="0">0</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                </select>
                              </div>
                              <div className="w-48">
                                <label>Months</label>
                                <select
                                  name="totalMonthOfExperience"
                                  onChange={onInputChangeHandler}
                                  value={data.totalMonthOfExperience}
                                >
                                  <option hidden value="">
                                    Please Select
                                  </option>
                                  <option value="0">0</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                </select>
                              </div>
                            </div>
                            {validationFormCheck && !data.totalExperience.length ? (
                              <span className="required__error__message">
                                Please Enter Total Experience
                              </span>
                            ) : data.totalExperience.length ? (
                              <span className="required__error__message">
                                {refers?.current[5]?.message}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="input-group2">
                          <div className="input-group w-100">
                            <label>
                              Relevant Experience{" "}
                              <span className="required_label">*</span>
                            </label>
                            <div className="flex">
                              <div className="w-48">
                                <label>Years</label>
                                <select
                                  name="relevantExperience"
                                  value={data.relevantExperience}
                                  ref={(element) => (refers.current[6] = element)}
                                  onChange={(e) => onInputChangeHandler(e, 6)}
                                >
                                  <option hidden value="">
                                    Please Select
                                  </option>
                                  <option value="0">0</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                </select>
                              </div>
                              <div className="w-48">
                                <label>Months</label>
                                <select
                                  name="totalMonthOfRelevantExperience"
                                  onChange={onInputChangeHandler}
                                  value={data.totalMonthOfRelevantExperience}
                                >
                                  <option hidden value="">
                                    Please Select
                                  </option>
                                  <option value="0">0</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                </select>
                              </div>
                            </div>
                            {validationFormCheck &&
                            !data.relevantExperience.length ? (
                              <span className="required__error__message">
                                Please Enter Relevant Experience
                              </span>
                            ) : data.relevantExperience.length ? (
                              <span className="required__error__message">
                                {refers?.current[6]?.message}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="input-group2">
                          <div className="input-group w-100">
                            <label>
                              Current Salary{" "}
                              <span className="required_label">*</span>
                            </label>
                            <div className="flex">
                              <div className="w-48">
                                <label>Per Year</label>
                                <input
                                  autoComplete="off"
                                  type="number"
                                  onKeyDown={(e) =>
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  min="0"
                                  name="currentSalaryPerYear"
                                  onWheel={(e) => e.target.blur()}
                                  id=""
                                  ref={(element) => (refers.current[7] = element)}
                                  value={data.currentSalaryPerYear}
                                  onChange={(e) => onInputChangeHandler(e, 7)}
                                  className="input-form"
                                  required={validationFormCheck}
                                />
                                {validationFormCheck &&
                                !data.currentSalaryPerYear.length ? (
                                  <span className="required__error__message">
                                    Please Enter Current Salary Per Year
                                  </span>
                                ) : data.currentSalaryPerYear.length ? (
                                  <span className="required__error__message">
                                    {refers?.current[7]?.message}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="w-48">
                                <label>Per Month</label>
                                <input
                                  autoComplete="off"
                                  type="number"
                                  onKeyDown={(e) =>
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  min="0"
                                  onWheel={(e) => e.target.blur()}
                                  name="currentSalaryPerMonth"
                                  id=""
                                  value={data.currentSalaryPerMonth}
                                  ref={(element) => (refers.current[8] = element)}
                                  onChange={(e) => onInputChangeHandler(e, 8)}
                                  className="input-form"
                                  required={validationFormCheck}
                                />
                                {validationFormCheck &&
                                !data.currentSalaryPerMonth.length ? (
                                  <span className="required__error__message">
                                    Please Enter Current Salary Per Month
                                  </span>
                                ) : data.currentSalaryPerMonth.length ? (
                                  <span className="required__error__message">
                                    {refers?.current[8]?.message}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="input-group2">
                          <div className="input-group w-100">
                            <label>
                              Expected Salary{" "}
                              <span className="required_label">*</span>
                            </label>
                            <div className="flex">
                              <div className="w-48">
                                <label>Per Year</label>
                                <input
                                  autoComplete="off"
                                  type="number"
                                  onKeyDown={(e) =>
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  min="0"
                                  onWheel={(e) => e.target.blur()}
                                  name="expectedSalaryPerYear"
                                  id=""
                                  ref={(element) => (refers.current[9] = element)}
                                  value={data.expectedSalaryPerYear}
                                  onChange={(e) => onInputChangeHandler(e, 9)}
                                  className="input-form"
                                  required={validationFormCheck}
                                />
                                {validationFormCheck &&
                                !data.expectedSalaryPerYear.length ? (
                                  <span className="required__error__message">
                                    Please Enter Expected Salary Per Year
                                  </span>
                                ) : data.expectedSalaryPerYear.length ? (
                                  <span className="required__error__message">
                                    {refers?.current[9]?.message}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="w-48">
                                <label>Per Month</label>
                                <input
                                  autoComplete="off"
                                  type="number"
                                  onKeyDown={(e) =>
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  min="0"
                                  onWheel={(e) => e.target.blur()}
                                  name="expectedSalaryPerMonth"
                                  ref={(element) => (refers.current[10] = element)}
                                  id=""
                                  value={data.expectedSalaryPerMonth}
                                  onChange={(e) => onInputChangeHandler(e, 10)}
                                  className="input-form"
                                  required={validationFormCheck}
                                />
                                {validationFormCheck &&
                                !data.expectedSalaryPerMonth.length ? (
                                  <span className="required__error__message">
                                    Please Enter Expected Salary Per Month
                                  </span>
                                ) : data.expectedSalaryPerMonth.length ? (
                                  <span className="required__error__message">
                                    {refers?.current[10]?.message}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="flex">
                        <div className="input-group">
                          <label>
                            Applied Role <span className="required_label">*</span>
                          </label>

                          <select
                            name="currentJobTitle"
                            value={data.currentJobTitle}
                            ref={(element) => (refers.current[4] = element)}
                            onChange={(e) => onInputChangeHandler(e, 4)}
                            className="input-form"
                            required={validationFormCheck}
                          >
                            <option hidden value="">
                              Please Select
                            </option>
                            {candidateSkillSetsArr.map((options, i) => (
                              <option key={i} value={options}>
                                {options}
                              </option>
                            ))}
                          </select>
                          {validationFormCheck && !data.currentJobTitle.length ? (
                            <span className="required__error__message">
                              Please Enter Current Job Title
                            </span>
                          ) : data.currentJobTitle.length ? (
                            <span className="required__error__message">
                              {refers?.current[4]?.message}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="input-group">
                          <label>
                            Skills <span className="required_label">*</span>
                          </label>

                          <Select
                            name="skillSet"
                            id=""
                            placeholder="Please Select"
                            value={skillSet}
                            onChange={(e) => {
                              setskillSet(e);
                              setskillSetErr(null);
                            }}
                            closeMenuOnSelect={false}
                            className={
                              skillSetErr
                                ? "multiSelectContainer multiSelectContainerErr"
                                : "multiSelectContainer"
                            }
                            isMulti={true}
                            maxLength={2}
                            options={multiSelect}
                          />
                          {skillSetErr && (
                            <span className="required__error__message">
                              {skillSetErr}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="flex">
                        <div className="input-group">
                          <label>Expected Joining Date </label>
                          <input
                            autoComplete="off"
                            type="date"
                            id=""
                            name="expectedJoiningDate"
                            onChange={onInputChangeHandler}
                            value={data.expectedJoiningDate}
                            className="input-form"
                            min={moment().format("YYYY-MM-DD")}
                          />
                        </div>
                        <div className="input-group">
                          <label>
                            Notice Period <span className="required_label">*</span>
                          </label>
                          <select
                            name="noticePeriod"
                            className="w-100"
                            value={data.noticePeriod}
                            onChange={(e) => onInputChangeHandler(e, 12)}
                            required={validationFormCheck}
                            ref={(element) => (refers.current[12] = element)}
                          >
                            <option hidden value="">
                              Please Select
                            </option>
                            <option value="15 Days">15 Days</option>
                            <option value="30 Days">30 Days</option>
                            <option value="45 Days">45 Days</option>
                            <option value="60 Days">60 Days</option>
                            <option value="90 Days">90 Days</option>
                            <option value="Immediate joiner">
                              Immediate joiner
                            </option>
                          </select>
                          {validationFormCheck && !data.noticePeriod.length ? (
                            <span className="required__error__message">
                              Please Select Notice Period
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="input-group">
                      <div className="flex">
                        <div className="input-group">
                          <label>Other Offers</label>
                          <select
                            className="input-form"
                            value={offerLetterLoader}
                            onChange={(e) => {
                              setOfferLetterLoader(e.target.value);
                              if (e.target.value === "false") {
                                setData({ ...data, offeredValue: "" });
                                setOtherOfferValueErr(null);
                              }
                            }}
                          >
                            <option hidden value="">
                              Please Select
                            </option>
                            <option value={"true"}>Yes</option>
                            <option value={"false"}>No</option>
                          </select>
                        </div>
                        <div className="input-group">
                          <label>
                            Offered Value In LPA{" "}
                            {offerLetterLoader === "true" && (
                              <span className="required_label"> *</span>
                            )}
                          </label>
                          <input
                            autoComplete="off"
                            className="input-form"
                            min="0"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            step={"0.1"}
                            accept=""
                            value={data.offerLetter}
                            onChange={(e) => {
                              onInputChangeHandler(e);
                              setOtherOfferValueErr(null);
                            }}
                            name="offeredValue"
                            disabled={offerLetterLoader === "false"}
                          />
                          {otherOfferValueErr && (
                            <span className="required__error__message">
                              {otherOfferValueErr}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="flex">
                        <div className="input-group">
                          <label className="lable-flex">
                            Serving Notice Period
                            <div className="">
                              <input
                                autoComplete="off"
                                name="isNegotiable"
                                type="checkbox"
                                onChange={handleNegotiatable}
                              />
                              <span> NP Negotiatable</span>
                            </div>
                          </label>
                          <select
                            id="servedNotice"
                            name="servedNoticePeriod"
                            onChange={onInputChangeHandler}
                            className="input-form"
                            value={data.servedNoticePeriod}
                          >
                            <option hidden value="">
                              Please Select
                            </option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </select>
                        </div>
                        <div className="input-group">
                          <label>
                            Source <span className="required_label">*</span>
                          </label>
                          <select
                            name="source"
                            value={data.source}
                            onChange={(e) => onInputChangeHandler(e, 14)}
                            required={validationFormCheck}
                            ref={(element) => (refers.current[14] = element)}
                          >
                            <option hidden value="">
                              Please Select
                            </option>
                            {SourceArr.map((opt, i) => (
                              <option key={i} value={opt}>
                                {opt}
                              </option>
                            ))}
                          </select>
                          {validationFormCheck && !data.source.length ? (
                            <span className="required__error__message">
                              Please Select Source
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="remark-area remark_level2">
                      <label className="mmb-10">Notes</label>
                      <Editor
                        value={data.remarks}
                        remarksUpdateHandler={(html) =>
                          onInputChangeHandler(html, 16, "remarks")
                        }
                        autoCapitalize="off"
                      />
                    </div>

                    <div className="input-group"></div>
                  </div>
                  {/* Address Details */}
                  <div className="subh-heading add-more">
                    <h4>Address Details</h4>
                    <p
                      className="cursor-pointer"
                      onClick={() => setShowAddress(!showAddress)}
                    >
                      {" "}
                      {showAddress ? "Hide" : "Show"}
                    </p>
                  </div>
                  {showAddress && (
                    <div className="group-input">
                      <div className="input-group">
                        <label>Current Address</label>
                        <textarea
                          rows="2"
                          id="current"
                          className="input-form"
                          name="currentAddress"
                          value={data.currentAddress}
                          onChange={onInputChangeHandler}
                        ></textarea>
                      </div>
                      <div className="input-group">
                        <label>Permanent Address</label>
                        <textarea
                          rows="2"
                          className="input-form"
                          id="permanent"
                          name="permanentAddress"
                          value={
                            !addressCheck
                              ? data.permanentAddress
                              : data.currentAddress
                          }
                          onChange={onInputChangeHandler}
                        ></textarea>
                        <div className="address-flex">
                          <input
                            autoComplete="off"
                            type="checkbox"
                            id="checkaddress"
                            onChange={handleAddessChecked}
                            name="address"
                            value="address"
                          />
                          <label htmlFor="checkaddress" className="mmt-5">
                            Same as Current Address
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Education Details */}
                  <div className="subh-heading add-more">
                    <h4>Education Details</h4>
                    <p
                      className="cursor-pointer"
                      onClick={() => addEducationFields()}
                    >
                      {" "}
                      + Add
                    </p>
                  </div>
                  {educationField &&
                    educationField.map((input, idx) => {
                      return (
                        <div className="form-info" key={`${input}-${idx}`}>
                          <div className="form-wrapper">
                            <div className="input-group">
                              <label>Institute Name</label>

                              <input
                                autoComplete="off"
                                type="text"
                                name="school"
                                id=""
                                value={input.school}
                                onChange={(event) =>
                                  onEducationHandleChange(idx, event)
                                }
                                className="input-form"
                              />
                            </div>
                            <div className="input-group">
                              <label>Degree</label>
                              <select
                                name="degree"
                                id=""
                                value={input.degree}
                                onChange={(event) =>
                                  onEducationHandleChange(idx, event)
                                }
                                className="input-form"
                              >
                                <option hidden value="">
                                  Please Select
                                </option>
                                <option value="10th">10th</option>
                                <option value="12th">12th</option>
                                <option value="Diploma">Diploma</option>
                                <option value="BE/B.Tech">BE/B.Tech</option>
                                <option value="M.Tech">M.Tech</option>
                                <option value="M.Sc">M.Sc</option>
                                <option value="MBA">MBA</option>
                                <option value="Degree">Degree</option>
                              </select>
                            </div>
                            <div className="input-group">
                              <label>
                                <span className="mmt-15">Start Date</span>
                              </label>

                              <input
                                autoComplete="off"
                                type="date"
                                name="startDate1"
                                max={moment().format("YYYY-MM-DD")}
                                id=""
                                value={input.startDate1}
                                onChange={(event) =>
                                  onEducationHandleChange(idx, event)
                                }
                                className="input-form"
                              />
                            </div>
                            <div className="input-group">
                              <label>
                                <span className="mmt-10">End Date</span>
                              </label>
                              <input
                                autoComplete="off"
                                type="date"
                                name="endDate1"
                                max={moment().format("YYYY-MM-DD")}
                                id=""
                                value={input.endDate1}
                                onChange={(event) =>
                                  onEducationHandleChange(idx, event)
                                }
                                className="input-form"
                              />
                            </div>
                            <div className="remove">
                              <button onClick={() => removeEducationFields(idx)}>
                                X
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  {/* Experience Details */}
                  <div className="subh-heading add-more">
                    <h4>Experience Details</h4>
                    <p
                      className="cursor-pointer"
                      onClick={() => addExperienceFields()}
                    >
                      {" "}
                      + Add
                    </p>
                  </div>
                  {experienceField.map((fieldee, idx) => {
                    return (
                      <div className="form-info" key={`${fieldee}-${idx}`}>
                        <div className="form-wrapper">
                          <div className="input-group">
                            <label>Company Name</label>
                            <input
                              autoComplete="off"
                              type="text"
                              name="company"
                              id=""
                              value={fieldee.company}
                              onChange={(event) =>
                                onExperienceHandleChange(idx, event)
                              }
                              className="input-form"
                            />
                          </div>
                          <div className="input-group">
                            <label>Job Title</label>
                            <input
                              autoComplete="off"
                              type="text"
                              name="jobTitle"
                              id=""
                              value={fieldee.jobTitle}
                              onChange={(event) =>
                                onExperienceHandleChange(idx, event)
                              }
                              className="input-form"
                            />
                          </div>

                          <div className="input-group">
                            <label>Start Date</label>
                            <input
                              autoComplete="off"
                              type="date"
                              name="exstartDate"
                              id=""
                              max={moment().format("YYYY-MM-DD")}
                              value={fieldee.exstartDate}
                              onChange={(event) =>
                                onExperienceHandleChange(idx, event)
                              }
                              className="input-form"
                            />
                          </div>
                          <div className="input-group">
                            <label>End Date</label>
                            <input
                              autoComplete="off"
                              type="date"
                              name="exendDate"
                              id=""
                              max={moment().format("YYYY-MM-DD")}
                              value={fieldee.exendDate}
                              onChange={(event) =>
                                onExperienceHandleChange(idx, event)
                              }
                              className="input-form"
                            />
                          </div>
                          <div className="remove">
                            <button onClick={removeExperienceField}>X</button>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  <div className="btn-section">
                    <button
                      className="btn-blue"
                      disabled={loadButton}
                      onClick={submitCandidateDetails}
                    >
                      {loadButton ? (
                        <i className="fa fa-circle-o-notch fa-spin"></i>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {conflictUserData && (
        <ExistingCandidateModal
          onClose={() => setConflictUserData(null)}
          userData={conflictUserData}
        />
      )}
    </>
  );
}

export default Addcandidate;
