import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Login from "src/containers/login";
import Ats from "src/containers/ats";
import Addcandidate from "src/containers/addCandidate";
import Candidateoverview from "src/containers/candidateOverview";
import AtsCandidatedetails from "src/containers/candidateDetails";

import Header from "src/components/header/index";
import { GoogleOAuthProvider } from "@react-oauth/google";
import AdminPanel from "src/containers/adminPanel";
import ReportsContainer from "src/containers/reports/index";

const authorization = localStorage.getItem("token");
const AppRoutes = () => {
  const [auth, setAuth] = useState(authorization ?? "");
  return (
    <div>
      <Router>
        <Header setAuth={setAuth} />
        <Routes>
          <Route
            exact
            path="/"
            element={
              <GoogleOAuthProvider clientId="34208548876-unrualm97sk7u2gcard831bpp7b0caab.apps.googleusercontent.com">
                <Login setAuth={setAuth} auth={auth} />{" "}
              </GoogleOAuthProvider>
            }
          />
          <Route
            exact
            path="/ats/candidate-overview"
            element={<Candidateoverview />}
          />
          <Route exact path="/ats/dashboard" element={<Ats />} />

          <Route exact path="/ats/add-candidate" element={<Addcandidate />} />
          <Route
            exact
            path="/ats/candidate-details/:id"
            element={<AtsCandidatedetails />}
          />
          <Route exact path="/admin-panel" element={<AdminPanel auth={auth} />} />
          <Route exact path="/reports" element={<ReportsContainer auth={auth} />} />
        </Routes>
        <ToastContainer />
      </Router>
    </div>
  );
};

export default AppRoutes;
