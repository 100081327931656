import React, { useState } from "react";
import "src/components/popup/popup.css";
import back from "src/assets/images/close icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteCandidate } from "src/services/addCandidate/addCandidate";
import { notificationSuccess, notificationError } from "src/utils/utils";

function Deletecandidate({ details, close, setRender }) {
  const [isLoadBtn, setIsLoadBtn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const deleteHandle = () => {
    setIsLoadBtn(true);
    deleteCandidate({ _id: details._id })
      .then(() => {
        navigate("/ats/candidate-overview");
        close();
        location.pathname === "/ats/candidate-overview" && setRender();

        notificationSuccess("Candidate deleted successfully");
        setIsLoadBtn(false);
      })
      .catch(() => {
        notificationError("Something went wrong");
        setIsLoadBtn(false);
        close();
      });
  };

  return (
    <>
      <div className="statusModal">
        <div className="statusModalContent">
          <div className="statusHead">
            <h5>Do you want to delete this candidate? </h5>
            <img width={30} src={back} onClick={close} alt="back" />
          </div>
          <div className="statusForm">
            <div className="dlt-popup">
              <p>
                Candidate Name : {details.firstName} {details.lastName}
              </p>
              <p>Candidate ID : {details.candidateID}</p>
              <p>Added By : {details.interviewBy} </p>
              <p>Status : {details.status} </p>
              <p>SkillSet : {details.skillSet[0].value}</p>
            </div>

            <div className="btn-section">
              <button className="btn-cancel" onClick={close}>
                Cancel
              </button>

              <div className="btn-section">
                <button className="btn-red" onClick={deleteHandle}>
                  {isLoadBtn ? (
                    <i className="fa fa-circle-o-notch fa-spin"></i>
                  ) : (
                    "Delete"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Deletecandidate;
