import moment from "moment";
import { toast } from "react-toastify";

export const ageCalculator = (dateOfBirth) => {
  var dob = new Date(dateOfBirth ? dateOfBirth : ""); //calculate month difference from current date in time
  var month_diff = Date.now() - dob.getTime(); //convert the calculated difference in date format
  var age_dt = new Date(month_diff); //extract year from date
  var year = age_dt.getUTCFullYear(); //now calculate the age of the user
  var agecalculate = Math.abs(year - 1970); //display the calculated age
  return agecalculate;
};

export const notificationSuccess = (msg) => {
  toast.success(msg, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  });
};

export const getNameInitials = (name = "") => {
  name = name?.trim?.();
  if (!name) return;

  let initials = name[0] || "";
  const index = name.indexOf(" ");
  if (index < name.length && index > 1) {
    initials += name[index + 1];
  }

  return initials.toUpperCase();
};

export const notificationError = (msg) => {
  toast.error(msg, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  });
};
export const isAuthenticated = (msg) => {
  if (msg === "Invalid Authorization") {
    return false;
  } else {
    return true;
  }
};

export const dateFormater = (date, formate = "YYYY-MM-DD") => {
  const newDate = moment(date).format(formate);
  return newDate;
};

export const formatDate = (date) => {
  return `${date.getFullYear()}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}`;
};

export const getStorageItem = (key) => {
  return localStorage.getItem(key);
};

export const removeStorageItem = (key) => {
  return localStorage.removeItem(key);
};

export const checkIsValid = (value = "", format) => {
  return format.test(value);
};

export const validEmployeeId = (id = "", format) => {
  return id.includes(format);
};

export const findLowercaseLetters = (str) => {
  return str.toUpperCase() === str;
};
export const getErrorDetails = (obj) => {
  let flag = false;
  for (let key in obj) {
    if (obj[key].isError !== null) {
      flag = true;
      return flag;
    }
  }
  return flag;
};
export const makeLabel = (str) => {
  let temp = str.charAt(0).toUpperCase();
  for (let i = 1; i < str.length; i++) {
    if (str[i].toUpperCase() === str[i]) {
      temp += " " + str[i];
    } else {
      temp += str[i];
    }
  }
  return temp;
};
export const validateData = (obj) => {
  const data = { ...obj };
  for (let key in obj) {
    if (
      (obj[key].value === "" || !obj[key].value) &&
      obj[key].required === true &&
      obj[key].type !== "file"
    ) {
      data[key].isError = `${makeLabel(key)} should not be empty`;
    }
    if (obj[key].valueFormat && obj[key].required === true) {
      let temp;
      if (obj[key].name === "employeeId") {
        temp = validEmployeeId(obj[key].value, obj[key].valueFormat);
      } else {
        temp = checkIsValid(obj[key].value, obj[key].valueFormat);
      }
      if (temp === false) {
        data[key].isError = `Enter valid ${makeLabel(key)}`;
      } else {
        data[key].isError = null;
      }
    }
    if (obj[key].type === "file" && obj[key].required === true) {
      if (obj[key].fileName === "") {
        data[key].isError = `Enter valid ${makeLabel(key)}`;
      } else {
        data[key].isError = null;
      }
    }
  }
  return data;
};
export const getFormData = async (obj, emergencyDetails = "") => {
  let formData = await new FormData();
  for (let key in obj) {
    if (key === "_id" || key === "modifiedDate" || key === "__v") {
      continue;
    } else if (
      obj[key].modified === false &&
      (key === "phoneNumber" || key === "personalEmailAddress")
    ) {
      continue;
    } else if (
      (key === "probationPeriod" || key === "internshipPeriod") &&
      obj[key].value === null
    ) {
      formData.append(key, "");
    } else if (key === "technologiesKnown") {
      let temp = obj[key].value.map((item) => item.value);
      formData.append("technologiesKnown", temp);
    } else {
      if (obj[key].type === "file") {
        formData.append(key, obj[key].fileName);
      } else {
        formData.append(key, obj[key].value);
      }
    }
  }
  if (
    emergencyDetails[0]?.name ||
    emergencyDetails[0]?.number ||
    emergencyDetails[0]?.relation
  ) {
    formData.append("emergencyDetail", JSON.stringify(emergencyDetails));
  }
  return formData;
};

export const formateResponseData = (obj, data) => {
  for (let key in data) {
    for (let value in obj) {
      if (
        key === "_id" ||
        key === "modifiedDate" ||
        key === "__v" ||
        key === "employeeBasicDetailsId"
      ) {
        continue;
      } else if (key === value) {
        if (obj[value].type === "file") {
          obj[key].filename = data[key];
          obj[key].fileName = data[key];
          obj[key].modified = false;
        } else if (
          obj[value].name === "maritalStatus" &&
          obj[value].value === "Married"
        ) {
          obj["spouseName"].disabled = false;
          obj[key].value = data[key];
          obj[key].modified = false;
        } else if (key === "technologiesKnown") {
          if (data[key].length > 0) {
            let temp = data[key].trim().split(",");
            let newArr = [];
            for (let i = 0; i < temp.length; i++) {
              newArr.push({ label: temp[i], value: temp[i] });
            }
            obj["technologiesKnown"].value = newArr;
            obj[key].modified = false;
          }
        } else {
          obj[key].value = data[key];
          obj[key].modified = false;
        }
      }
    }
  }
  return obj;
};
export const resetData = (obj) => {
  for (let key in obj) {
    if (obj[key].type === "file") {
      obj[key].fileName = "";
      obj[key].filename = "";
      obj[key].modified = false;
    } else {
      obj[key].value = "";
      obj[key].modified = false;
    }
  }
  return obj;
};
export const numberWithCommas = (x) => {
  return x.toString().split(".")[0].length > 3
    ? x
        .toString()
        .substring(0, x.toString().split(".")[0].length - 3)
        .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
        "," +
        x.toString().substring(x.toString().split(".")[0].length - 3)
    : x.toString();
};

export const getTotalCount = (obj) =>
  Object.values(obj).reduce((acc, currentValue) => acc + currentValue, 0);

export const isObjEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};
