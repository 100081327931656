import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

function Card({ data }) {
  return (
    <div className="data-card-data">
      <h5>
        <label>
          <strong>Name : </strong>{" "}
          <span className="ellipsis">
            <Link to={`/ats/candidate-details/${data._id}`}>
              {data.firstName + " " + data.lastName}
            </Link>
          </span>
        </label>
        <label>
          <strong className="ellipsis">
            {moment(data?.expectedJoiningDate ?? data.modifiedDate).format(
              "DD MMM YYYY"
            )}{" "}
          </strong>
        </label>
      </h5>
      <h5></h5>
      <h5>
        <label>
          <strong>Skills : </strong>{" "}
          <span className="ellipsis">
            {data.skillSet.map((item, index) =>
              index === data.skillSet.length - 1 ? (
                <span key={index}>{item.label}</span>
              ) : (
                <span key={index}>
                  {item.label}
                  {", "}
                </span>
              )
            )}
          </span>
        </label>
      </h5>
    </div>
  );
}

export default Card;
