import React from "react";
import "./skeletonLoader.css";

const SkeletonLoader = ({ isTable = false }) => {
  return (
    <div className="cardskeleton is-loading">
      {isTable ? (
        <div className="contentTable">
          <h1></h1>
          <h1></h1>
          <h1></h1>
          <h1></h1>
          <h1></h1>
          <h1></h1>
        </div>
      ) : (
        <div className="content">
          <h2></h2>
          <p></p>
          <h2></h2>
          <p></p>
          <h2></h2>
          <p></p>
          <h2></h2>
          <p></p>
        </div>
      )}
    </div>
  );
};

export default SkeletonLoader;
