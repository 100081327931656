import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest
} from "src/utils/httpHelper";
import { adminPanelUrl } from "src/utils/urlContainers";
import { attachParams } from "src/utils/urlHelper";

export const getAllUser = async (data) => {
  return await getRequest({
    url: attachParams(adminPanelUrl?.GET_ALL_USERS, data)
  });
};

export const deleteUser = async (data) => {
  return await deleteRequest({
    url: attachParams(adminPanelUrl?.DELETE_USER, data)
  });
};

export const addAdminUser = async (data) => {
  return await postRequest({
    url: adminPanelUrl?.ADD_USER,
    data: data
  });
};
export const updateAdminUser = async (data) => {
  return await putRequest({
    url: adminPanelUrl?.UPDATE_USER,
    data: data
  });
};
