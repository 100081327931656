import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./subSidebar.css";

const AdminPanelSideBar = ({ subChildren }) => {
  const location = useLocation();
  const [subActiveState, setSubActiveState] = useState(location.pathname);
  useEffect(() => {
    setSubActiveState(location.pathname);
  }, []);

  return (
    subChildren.length && (
      <div className="subsidebar-section subsidebar-section-admin ">
        <ul>
          {subChildren?.map((item, index) => {
            return (
              <li
                className={
                  subActiveState === item?.routing ? "active mmt-5" : "mmt-5"
                }
                key={index}
              >
                <Link
                  to={item?.routing}
                  onClick={() => setSubActiveState(item?.routing)}
                >
                  {item?.dashBoardTitle}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    )
  );
};

export default AdminPanelSideBar;
