import moment from "moment";

export const validationContainer = {
  emailFormat: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  nameFormat: /^[A-Za-z ]+$/,
  mobileNumberFormat: /^[6789]\d{9}$/,
  employeeIdFormat: "SB00",
  adhaarCardFormat: /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
  panCardFormat: /[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
  pfFormat: /[A-Z]{5}[0-9]{17}$/,
  IFSCFormat: /[A-Z]{4}0[A-Z0-9]{6}$/,
  gstformat: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
};

export const basicDetailsInitialState = {
  firstName: {
    name: "firstName",
    type: "text",
    field: "input",
    value: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null,
    valueFormat: /^[A-Za-z ]+$/
  },
  lastName: {
    name: "lastName",
    type: "text",
    field: "input",
    value: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null,
    valueFormat: /^[A-Za-z ]+$/
  },
  fatherName: {
    name: "fatherName",
    type: "text",
    field: "input",
    value: "",
    required: false,
    disabled: false,
    modified: false,
    isError: null,
    valueFormat: /^[A-Za-z ]+$/
  },
  personalEmailAddress: {
    name: "personalEmailAddress",
    type: "email",
    field: "input",
    value: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null,
    valueFormat: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
  },
  phoneNumber: {
    name: "phoneNumber",
    type: "number",
    field: "input",
    value: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null,
    valueFormat: /^[6789]\d{9}$/
  },
  gender: {
    name: "gender",
    type: "",
    field: "Select",
    value: "",
    required: true,
    options: ["Male", "Female"],
    disabled: false,
    modified: false,
    isError: null
  },
  dateOfBirth: {
    name: "dateOfBirth",
    type: "date",
    field: "input",
    value: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null,
    min: moment().subtract(100, "year").format("YYYY-MM-DD"),
    max: moment().subtract(17, "year").format("YYYY-MM-DD")
  },
  age: {
    name: "age",
    type: "number",
    field: "input",
    value: "",
    required: false,
    disabled: true,
    modified: false,
    isError: null
  },
  bloodGroup: {
    name: "bloodGroup",
    type: "",
    field: "Select",
    value: "",
    required: true,
    disabled: false,
    options: ["A+", "B+", "O+", "AB+", "A-", "B-", "O-", "AB-"],
    modified: false,
    isError: null
  },

  maritalStatus: {
    name: "maritalStatus",
    type: "",
    field: "Select",
    value: "",
    required: true,
    disabled: false,
    options: ["UnMarried", "Married"],
    modified: false,
    isError: null
  },
  spouseName: {
    name: "spouseName",
    type: "text",
    field: "input",
    value: "",
    required: false,
    disabled: true,
    modified: false,
    isError: null,
    valueFormat: /^[A-Za-z ]+$/
  },

  currentAddress: {
    name: "currentAddress",
    type: "text",
    field: "text-area",
    value: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null
  },
  permanentAddress: {
    name: "permanentAddress",
    type: "text",
    field: "text-area",
    required: true,
    value: "",
    disabled: false,
    modified: false,
    isError: null
  },
  picture: {
    name: "picture",
    type: "file",
    field: "input",
    filename: "",
    required: false,
    disabled: false,
    modified: false,
    isError: null,
    accept: "image/*"
  }
};
export const workDetailsInitialState = {
  employeeId: {
    name: "employeeId",
    type: "text",
    field: "input",
    value: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null,
    valueFormat: "SB00"
  },
  workEmail: {
    name: "workEmail",
    type: "email",
    field: "input",
    value: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null,
    valueFormat: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
  },
  sourceOfHiring: {
    name: "sourceOfHiring",
    type: "",
    field: "Select",
    value: "",
    required: true,
    disabled: false,
    options: ["Naukri", "Linkedin", "Indeed", "Referral", "Others"],
    modified: true,
    isError: null
  },
  department: {
    name: "department",
    type: "",
    field: "Select",
    value: "",
    required: true,
    disabled: false,
    options: [
      "Admin",
      "Management",
      "Product Developer",
      "Sales",
      "Hr",
      "Product Designer"
    ],
    modified: true,
    isError: null
  },
  designation: {
    name: "designation",
    type: "",
    field: "Select",
    value: "",
    required: true,
    disabled: false,
    options: ["Angular", "React Js", "Node Js", "UI Developer", "UI UX Designer"],
    modified: true,
    isError: null
  },
  dateOfJoining: {
    name: "dateOfJoining",
    type: "date",
    field: "input",
    value: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null,
    min: moment().subtract(1, "month").format("YYYY-MM-DD"),
    max: moment().format("YYYY-MM-DD")
  },
  employmentType: {
    name: "employmentType",
    type: "",
    field: "Select",
    value: "",
    required: true,
    disabled: false,
    options: ["FullTime", "Probationary", "FresherIntern"],
    modified: true,
    isError: null
  },
  probationPeriod: {
    name: "probationPeriod",
    type: "date",
    field: "input",
    value: undefined,
    required: false,
    disabled: false,
    modified: false,
    isError: null,
    max: moment().add(1, "year").format("YYYY-MM-DD"),
    min: moment().subtract(2, "month").format("YYYY-MM-DD")
  },
  internshipPeriod: {
    name: "internshipPeriod",
    type: "date",
    field: "input",
    value: undefined,
    required: false,
    disabled: false,
    modified: false,
    isError: null,
    max: moment().add(1, "year").format("YYYY-MM-DD"),
    min: moment().subtract(2, "month").format("YYYY-MM-DD")
  },
  fullTimeConfirmationDate: {
    name: "fullTimeConfirmationDate",
    type: "date",
    field: "input",
    value: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null,
    max: moment().add(1, "year").format("YYYY-MM-DD"),
    min: moment().format("YYYY-MM-DD")
  },
  technologiesKnown: {
    name: "technologiesKnown",
    type: "Multi-Select",
    field: "Select",
    value: undefined,
    required: true,
    disabled: false,
    options: [
      "Angular",
      "React",
      "node js",
      "UX design",
      "UI design",
      "UI-UX development",
      "HTML",
      "next js",
      "vue js",
      "express"
    ],
    modified: true,
    isError: null
  },
  totalExperience: {
    name: "totalExperience",
    type: "number",
    field: "input",
    value: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null
  },
  relevantExperience: {
    name: "relevantExperience",
    type: "number",
    field: "input",
    value: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null
  },
  backgroundCheckVerifiedBy: {
    name: "backgroundCheckVerifiedBy",
    type: "",
    field: "Select",
    value: "",
    required: true,
    disabled: false,
    options: ["Not Verified", "Laxmi", "Laxmi Narasimhan", "Bharati"],
    modified: false,
    isError: null
  },
  remark: {
    name: "remark",
    type: "text",
    field: "text-area",
    value: "",
    required: false,
    disabled: false,
    modified: false,
    isError: null
  },
  resume: {
    name: "resume",
    type: "file",
    field: "input",
    filename: "",
    required: false,
    disabled: false,
    modified: false,
    isError: null,
    accept: ".pdf"
  }
};
export const documentDetailsInitialState = {
  aadharNumber: {
    name: "aadharNumber",
    type: "number",
    field: "input",
    value: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null,
    valueFormat: /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/
  },
  panNumber: {
    name: "panNumber",
    type: "text",
    field: "input",
    value: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null,
    valueFormat: /[A-Z]{5}[0-9]{4}[A-Z]{1}$/
  },
  aadharFrontAttachment: {
    name: "aadharFrontAttachment",
    type: "file",
    field: "input",
    filename: "",
    fileName: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null,
    accept: ".pdf"
  },
  aadharBackAttachment: {
    name: "aadharBackAttachment",
    type: "file",
    field: "input",
    filename: "",
    fileName: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null,
    accept: ".pdf"
  },
  panAttachment: {
    name: "panAttachment",
    type: "file",
    field: "input",
    filename: "",
    fileName: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null,
    accept: ".pdf"
  },

  tenthMarksSheetAttachment: {
    name: "tenthMarksSheetAttachment",
    type: "file",
    field: "input",
    filename: "",
    fileName: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null,
    accept: ".pdf"
  },
  twelvethMarksSheetAttachment: {
    name: "twelvethMarksSheetAttachment",
    type: "file",
    field: "input",
    filename: "",
    fileName: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null,
    accept: ".pdf"
  },
  graduationSheetAttachment: {
    name: "graduationSheetAttachment",
    type: "file",
    field: "input",
    filename: "",
    fileName: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null,
    accept: ".pdf"
  },
  payslipAttachmentOne: {
    name: "payslipAttachmentOne",
    type: "file",
    field: "input",
    filename: "",
    required: false,
    disabled: false,
    modified: false,
    isError: null,
    accept: ".pdf"
  },
  offerLetter: {
    name: "offerLetter",
    type: "file",
    field: "input",
    filename: "",
    required: false,
    disabled: false,
    modified: false,
    isError: null,
    accept: ".pdf"
  },
  relievingOrExperienceCertificate: {
    name: "relievingOrExperienceCertificate",
    type: "file",
    field: "input",
    filename: "",
    required: false,
    disabled: false,
    modified: false,
    isError: null,
    accept: ".pdf"
  }
};
export const bankDetailsInitialState = {
  nameAsPerBank: {
    name: "nameAsPerBank",
    type: "text",
    field: "input",
    value: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null,
    valueFormat: /^[A-Za-z ]+$/
  },
  accountNumber: {
    name: "accountNumber",
    type: "number",
    field: "input",
    value: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null
  },
  accountType: {
    name: "accountType",
    type: "",
    field: "Select",
    value: "",
    required: true,
    disabled: false,
    options: ["Savings", "Salary"],
    modified: false,
    isError: null
  },
  bankName: {
    name: "bankName",
    type: "text",
    field: "input",
    value: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null,
    valueFormat: /^[A-Za-z ]+$/
  },
  branchName: {
    name: "branchName",
    type: "text",
    field: "input",
    value: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null,
    valueFormat: /^[A-Za-z ]+$/
  },
  ifscCode: {
    name: "ifscCode",
    type: "text",
    field: "input",
    value: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null,
    valueFormat: /[A-Z]{4}0[A-Z0-9]{6}$/
  },

  uanNumber: {
    name: "uanNumber",
    type: "text",
    field: "input",
    value: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null,
    valueFormat: /^[0-9]\d{11}$/
  },
  pfNumber: {
    name: "pfNumber",
    type: "text",
    field: "input",
    value: "",
    required: true,
    disabled: false,
    modified: false,
    isError: null,
    valueFormat: /[A-Z]{5}[0-9]{17}$/
  },
  esiNumber: {
    name: "esiNumber",
    type: "number",
    field: "input",
    value: "",
    required: false,
    disabled: false,
    modified: false,
    isError: null,
    valueFormat: /^[0-9]\d{17}$/
  },
  joinDate: {
    name: "joinDate",
    type: "date",
    field: "input",
    value: "",
    required: false,
    disabled: false,
    modified: false,
    isError: null,
    min: moment().subtract(100, "year").format("YYYY-MM-DD"),
    max: moment().format("YYYY-MM-DD")
  }
};
