import { configureStore } from "@reduxjs/toolkit";
import analyticsReducer from "./analytics/analyticsSlice";
import candidateOverviewReducer from "src/redux/candidateOverview/candidateOverviewSlice";
import candidateDashboardReducer from "src/redux/candidateDashboard/candidateDashboardSlice";
import loginReducer from "src/redux/login/loginSlice";

export default configureStore({
  reducer: {
    candidateOverview: candidateOverviewReducer,
    candidateDashboard: candidateDashboardReducer,
    analytics: analyticsReducer,
    login: loginReducer
  }
});
