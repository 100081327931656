import { getRequest } from "src/utils/httpHelper";
import { candidateDashboardUrl } from "src/utils/urlContainers";
import { attachParams } from "src/utils/urlHelper";

export const getDashboardReqApi = async () => {
  return await getRequest({
    url: candidateDashboardUrl?.GET_DASHBOARD_DATA
  });
};

export const getDashboardOfferAcceptedReqApi = async (data) => {
  return await getRequest({
    url: attachParams(candidateDashboardUrl?.GET_OFFER_ACCEPTED, data)
  });
};

export const getDashboardTechReqApi = async (data) => {
  return await getRequest({
    url: attachParams(candidateDashboardUrl?.GET_DASHBOARD_DATA, data)
  });
};

export const getDashboardStartEndDateReqApi = async (data) => {
  return await getRequest({
    url: attachParams(candidateDashboardUrl?.GET_DASHBOARD_DATA, data)
  });
};
