import React, { useState } from "react";
import "src/components/popup/popup.css";
import back from "src/assets/images/close icon.svg";

import { notificationSuccess, notificationError } from "src/utils/utils";
import { updateCandidateStatusReqApi } from "src/services/addCandidate/addCandidate";
import { candidateStatusCustom } from "src/utils/uiConstants";
import Editor from "src/components/quillEditor/index";

function Atsstatus({ candidate, close, id, onChangeStatus }) {
  const [loading, setLoading] = useState(false);
  const { name } = JSON.parse(localStorage.getItem("user"));

  const [CandidateStatusList, setCandidateStatusList] = useState({
    status: candidate.status,
    remarks: "",
    modifiedBY: name,
    _id: id
  });
  const [error, setError] = useState({
    status: null,
    remarks: null
  });

  const onStatusDetailsSubmit = () => {
    if (CandidateStatusList.status === "") {
      setError({ ...error, status: "Status cannot be empty" });
      return;
    }
    if (CandidateStatusList.remarks === "") {
      setError({ ...error, remarks: "Remarks cannot be empty" });
      return;
    }

    if (CandidateStatusList.status) {
      postData();
    }
  };

  const postData = async () => {
    if (CandidateStatusList.status !== "") {
      setLoading(true);

      await updateCandidateStatusReqApi(CandidateStatusList).then(async (res) => {
        if (res.data.code === 200) {
          notificationSuccess(res.data.message);
          onChangeStatus?.();
          close();
        } else {
          notificationError(res.data.message);
          setLoading(false);
        }
      });
    }
  };
  const onStatusDetailsHandler = (e) => {
    setCandidateStatusList({
      ...CandidateStatusList,
      [e.target.name]: e.target.value
    });
    setError({ ...error, [e.target.name]: null });
  };

  const remarksUpdateHandler = (res) => {
    setCandidateStatusList({
      ...CandidateStatusList,
      remarks: res
    });
    setError({ ...error, remarks: null });
  };

  return (
    <>
      <div className="statusModal overview_status">
        <div className="statusModalContent">
          <div className="statusHead">
            <h3>Change Status</h3>
            <img src={back} onClick={close} alt="back" width={30} />
          </div>
          <div className="statusForm overview_status_content">
            <div className="input-group">
              <label>
                Change Status <span className="required_label">*</span>
              </label>
              <select
                className={error.status ? "err-border " : ""}
                name="status"
                value={CandidateStatusList.status}
                onChange={onStatusDetailsHandler}
              >
                <option value="" disabled>
                  Please Select
                </option>
                {candidateStatusCustom.map((opt, v) => (
                  <option key={v} value={opt.api}>
                    {opt.name}
                  </option>
                ))}
              </select>
              {error.status && <span className="sub-p2">{error.status}</span>}
            </div>

            <div className="input-group mmt-20">
              <label>
                Comments <span className="required_label">*</span>
              </label>

              <Editor
                value={CandidateStatusList.remarks}
                remarksUpdateHandler={remarksUpdateHandler}
                autoCapitalize="off"
              />
              <span className={error.remarks ? "sub-p2" : "sub-p"}>
                {error.remarks
                  ? `${error.remarks}`
                  : " Please add review, reference and links here"}
              </span>
            </div>

            <div className="btn-section mmt-20">
              <button
                className="btn-blue"
                disabled={loading}
                onClick={onStatusDetailsSubmit}
              >
                {loading ? <i className="fa fa-circle-o-notch fa-spin"></i> : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Atsstatus;
