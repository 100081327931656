import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import back from "src/assets/images/back.svg";
import ellipsis from "src/assets/images/ellipsis.svg";
import { useParams } from "react-router-dom";
import Atsdetails from "src/containers/candidateDetails/AtsDetails";
import Atspipeline from "src/components/candidateDetails/AtsPipeline";
import Editcandidate from "src/components/popup/atsPopups/EditCandidate";
import Loader from "src/components/loader";
import Deletecandidate from "src/components/popup/atsPopups/DeleteCandidate";
import { useDispatch, useSelector } from "react-redux";
import { getSingleCandidateDetails } from "src/redux/candidateOverview/action";

const Atsemployeedetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("userAuth");
  let userRole = localStorage.getItem("user");
  let { role } = JSON.parse(userRole);
  const { candidateDetails, loader } = useSelector(
    (state) => state?.candidateOverview
  );
  const [tab, setTab] = useState(true);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [openEditCandidate, setOpenEditCandidate] = useState(false);
  const [openDeleteCandidate, setOpenDeleteCandidate] = useState(false);

  const getDetails = async (id) => {
    await dispatch(getSingleCandidateDetails({ _id: id }));
    const isLoggedIn = localStorage.getItem("userAuth");
    if (isLoggedIn !== "true") {
      localStorage.clear();
      localStorage.setItem("userAuth", "false");
      navigate("/");
    }
  };

  useEffect(() => {
    if (isLoggedIn === "true") {
      getDetails(id);
    } else {
      localStorage.clear();
      localStorage.setItem("userAuth", "false");
      navigate("/");
    }
  }, [updated, id]);

  const closeEditDetails = () => {
    setOpenEditCandidate(false);
  };

  const closeDeleteDetails = () => {
    setOpenDeleteCandidate(false);
  };
  const openHandler = () => {
    setOpen(true);
  };

  return (
    isLoggedIn === "true" && (
      <>
        <div className="heading-section">
          <p className="back-to cursor-pointer" onClick={() => navigate(-1)}>
            <img src={back} alt="back" />
            <span>Back</span>
          </p>
        </div>
        <div
          className="viewemployee-section"
          style={{ backgroundColor: "#fff", paddingBottom: "20px" }}
        >
          {openEditCandidate ? (
            <Editcandidate
              id={id}
              close={closeEditDetails}
              candidate={candidateDetails}
              setRender={() => getDetails(id)}
            />
          ) : (
            ""
          )}
          {openDeleteCandidate ? (
            <Deletecandidate
              details={candidateDetails}
              close={closeDeleteDetails}
              setRender={() => getDetails(id)}
              _
            />
          ) : (
            ""
          )}
          {loader ? (
            ""
          ) : (
            <>
              {/* Tabs */}
              <div className="list-view" id="basic">
                <div className="detail-tabs">
                  <div>
                    <h2
                      onClick={() => {
                        setTab(true);
                      }}
                      className={`${tab ? "active" : ""}`}
                    >
                      Details
                    </h2>
                    <h2
                      onClick={() => {
                        setTab(false);
                      }}
                      className={`${tab ? "" : "active"}`}
                    >
                      Pipeline
                    </h2>
                  </div>

                  {tab ? (
                    <div style={{ position: "relative", gap: "40px" }}>
                      <span>
                        {candidateDetails.resume ? (
                          <a
                            href={candidateDetails.resume}
                            target="_blank"
                            rel="noreferrer"
                          >
                            View Resume
                          </a>
                        ) : (
                          "No Resume"
                        )}
                      </span>
                      <span className="status-pp">{candidateDetails.status}</span>
                      {(role === "Admin" ||
                        role === "Manager" ||
                        role === "Recruiters") && (
                        <img
                          src={ellipsis}
                          alt="ellipsis"
                          className="ellipsis-image"
                          onMouseOver={() => setOptions(true)}
                          onMouseLeave={() => setOptions(false)}
                        />
                      )}
                      {(role === "Admin" ||
                        role === "Manager" ||
                        role === "Recruiters") &&
                      options ? (
                        <ul
                          onMouseOver={() => setOptions(true)}
                          onMouseLeave={() => setOptions(false)}
                          onClick={(e) => {
                            if (!e.target.className.includes("option-bar")) {
                              setOptions(false);
                            }
                          }}
                          className="filter-dropdown after-filter-dropdown"
                        >
                          <li
                            onClick={() => {
                              setOpenEditCandidate(true);
                              setOptions(false);
                            }}
                          >
                            Edit
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div style={{ position: "relative" }}>
                      <button className="btn-blue" onClick={openHandler}>
                        Change Status
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {tab ? (
                <Atsdetails id={id} details={candidateDetails} />
              ) : (
                <Atspipeline
                  id={id}
                  details={candidateDetails}
                  setUpdated={setUpdated}
                  open={open}
                  setOpen={setOpen}
                  openHandler={openHandler}
                />
              )}
            </>
          )}
          {loader ? <Loader /> : ""}
        </div>
      </>
    )
  );
};

export default Atsemployeedetails;
