export const emergencyDetails = [
  [
    {
      lable: "Emergency Contact Name",
      name: "name",
      type: "text",
      value: "",
      field: "input",
      required: true,
      disabled: false,
      modified: false,
      isError: null
    },
    {
      lable: "Emergency Contact Number",
      name: "phoneNumber",
      type: "number",
      value: "",
      field: "input",
      required: true,
      disabled: false,
      modified: false,
      isError: null
    },
    {
      lable: "Emergency Contact Relationship",
      name: "relation",
      type: "text",
      value: "",
      field: "input",
      required: true,
      disabled: false,
      modified: false,
      isError: null
    }
  ]
];

export const newBasicDetails = [
  {
    name: "firstName",
    type: "text",
    value: "",
    field: "input",
    required: true,
    disabled: false,
    modified: false,
    isError: "First Name should not be empty"
  },

  {
    name: "lastName",
    type: "text",
    value: "",
    field: "input",
    required: true,
    disabled: false,
    modified: false,
    isError: "Last Name should not be empty"
  },

  {
    name: "fatherName",
    type: "text",
    value: "",
    field: "input",
    required: false,
    disabled: false,
    modified: false,
    isError: null
  },
  {
    name: "personalEmailAddress",
    type: "email",
    value: "",
    field: "input",
    required: true,
    disabled: false,
    modified: false,
    isError: "Personal email should not be empty"
  },

  {
    name: "phoneNumber",
    type: "number",
    value: "",
    field: "input",
    required: true,
    disabled: false,
    modified: false,
    isError: "Phone number should not be empty"
  },

  {
    name: "gender",
    type: "",
    value: "Male",
    field: "Select",
    required: true,
    options: ["Male", "Female"],
    disabled: false,
    modified: true,
    isError: null
  },

  {
    name: "dateOfBirth",
    type: "date",
    value: "",
    field: "input",
    required: true,
    disabled: false,
    modified: false,
    isError: "DOB should not be empty"
  },

  {
    name: "age",
    type: "number",
    value: "",
    field: "input",
    required: true,
    disabled: true,
    modified: false,
    isError: null
  },
  {
    name: "bloodGroup",
    type: "text",
    value: "A+",
    field: "Select",
    required: true,
    disabled: false,
    options: ["A+", "B+", "O+", "AB+", "A-", "B-", "O-", "AB-"],
    modified: true,
    isError: null
  },

  {
    name: "maritalStatus",
    type: "text",
    value: "UnMarried",
    field: "Select",
    required: true,
    disabled: false,
    options: ["UnMarried", "Married"],
    modified: true,
    isError: null
  },

  {
    name: "spouseName",
    type: "text",
    value: "",
    field: "input",
    required: false,
    disabled: true,
    modified: false,
    isError: null
  },

  {
    name: "currentAddress",
    type: "text",
    value: "",
    field: "text-area",
    required: true,
    disabled: false,
    modified: false,
    isError: "Current Address should not be empty"
  },

  {
    name: "permanentAddress",
    type: "text",
    value: "",
    field: "text-area",
    required: true,
    disabled: false,
    modified: false,
    isError: "Permanent Address should not be empty"
  },
  {
    name: "picture",
    type: "file",
    value: "",
    field: "input",
    required: false,
    disabled: false,
    modified: false,
    isError: null
  }
];

export const allStatuses = [
  "Candidates",
  "Screening",
  "Round 1",
  "Validation",
  "Round 2",
  "HR Round",
  "Offered",
  "Offer Accepted",
  "Offer Declined",
  "Joined",
  "Rejected"
];
