import React from "react";
import { useLocation } from "react-router-dom";
import Atsdashboard from "../analytics/atsanalytics";

const Ats = () => {
  const location = useLocation();
  location.header = "/ats/dashboard";

  return (
    <div>
      <div className="main-wrapper">
        <div className="main-body">{<Atsdashboard />}</div>
      </div>
    </div>
  );
};

export default Ats;
