// const APP_API_URL = process.env;
export const BASE_URL = `https://api.dev.sketchbrahma.com/sketch-one-hr`;
export const LOCAL_BASE_URL = "https://api.dev.sketchbrahma.com";
// export const BASE_URL = `http://localhost:8000/sketch-one-hr`;
// export const LOCAL_BASE_URL = "http://localhost:8000";

const postNewEmpUrl = {
  BASIC_DETAILS: `${BASE_URL}/add/employee/basicDetails`,
  WORK_DETAILS: `${BASE_URL}/update/employee/workDetails`,
  BANK_DETAILS: `${BASE_URL}/update/employee/bankDetails`,
  DOCUMENT_DETAILS: `${BASE_URL}/update-v1/employee/documentDetails`,
  UPDATE_BASIC_DETAILS: `${BASE_URL}/update/employee/basicDetails`
};
Object.freeze(postNewEmpUrl);

const getEmpDetailsUrl = {
  ALL_DETAILS: `${BASE_URL}/get/employee`,
  BASIC_DETAILS: BASE_URL + "/get/employee/basicDetails",
  WORK_DETAILS: BASE_URL + "/get/employee/workDetails",
  BANK_DETAILS: BASE_URL + "/get/employee/bankDetails",
  DOCUMENT_DETAILS: BASE_URL + "/get/employee/documentDetails"
};
Object.freeze(getEmpDetailsUrl);

const updateEmpDetailsUrl = {
  BASIC_DETAILS: `${BASE_URL}/update/employee/basicDetails`,
  WORK_DETAILS: `${BASE_URL}/update/employee/workDetails`,
  BANK_DETAILS: `${BASE_URL}/update/employee/bankDetails`,
  DOCUMENT_DETAILS: `${BASE_URL}/update-v1/employee/documentDetails`,
  EXIT_DETAILS: `${BASE_URL}/exit/employee`,
  DELETE_DETAILS: `${BASE_URL}/delete/employee`
};

Object.freeze(updateEmpDetailsUrl);

const getEmpOverviewUrl = {
  ALL_EMPLOYEES: `${BASE_URL}/get/allEmployee`,
  SEARCH_EMPLOYEES: `${BASE_URL}/get/employees/search`
};

Object.freeze(getEmpOverviewUrl);

const candidateOverviewUrl = {
  GET_ALL_CANDIDATES: `${BASE_URL}/hiring/get/allCandidates`,
  CANDIDATE_DETAILS: `${BASE_URL}/hiring/get/candidate`,
  PIPELINE_DETAILS: `${BASE_URL}/hiring/get/candidate/status`,
  DELETE_CANDIDATE: `${BASE_URL}/delete/candidate`,
  SEARCH_CANDIDATE: `${BASE_URL}/hiring/get/candidate/search`,
  DUPLICATE_CHECK: `${BASE_URL}/hiring/candidate/duplicate-check`,
  ADD_CANDIDATE: `${BASE_URL}/hiring/add/candidates`,
  CANDIDATEOVERVIEW_BASEURL: `/ats/candidate-overview`,
  UPDATE_CANDIDATE: `${BASE_URL}/hiring/update/candidates`,
  UPDATE_STATUS: `${BASE_URL}/hiring/candidate/updateStatus`,
  FILTER_CANDIDATE: `${BASE_URL}/hiring/get/candidate/filter`,
  DELETE_STATUS: `${BASE_URL}/hiring/candidate/deletestatus`,
  REJECTED_CANDIDATE: `${BASE_URL}/hiring/get/rejected/candidates/filter`
};

Object.freeze(candidateOverviewUrl);

const candidateDashboardUrl = {
  GET_DASHBOARD_DATA: `${BASE_URL}/hiring/get/candidate/analytics`,
  GET_OFFER_ACCEPTED: `${BASE_URL}/get/candidates/offerAccepted`
};

Object.freeze(candidateDashboardUrl);

const analyticsUrl = {
  EMPLOYEE_DETAILS: `${BASE_URL}/get/employee/analytics`
};
Object.freeze(analyticsUrl);

const customerOverviewUrl = {
  DELETE_CUSTOMER: `${BASE_URL}/customer/delete/customerDetails`,
  CUSTOMER_DETAILS: `${BASE_URL}/customer/get/customerDetails`,
  ALL_CUSTOMERS: `${BASE_URL}/customer/get/allCustomers`,
  SEARCH_CUSTOMERS: `${BASE_URL}/customer/get/search`,
  ADD_CUSTOMER: `${BASE_URL}/customer/add/customerDetails`,
  UPDATE_CUSTOMER: `${BASE_URL}/customer/update/customerDetails`
};
Object.freeze(customerOverviewUrl);

const googleLoginUrl = {
  login: `${LOCAL_BASE_URL}/google/login`
};

Object.freeze(googleLoginUrl);

const adminPanelUrl = {
  DELETE_USER: `${BASE_URL}/delete/user`,
  GET_ALL_USERS: `${BASE_URL}/getAllUser`,
  ADD_USER: `${BASE_URL}/create/user`,
  UPDATE_USER: `${BASE_URL}/update/user`
};
Object.freeze(adminPanelUrl);

export {
  postNewEmpUrl,
  getEmpDetailsUrl,
  updateEmpDetailsUrl,
  candidateOverviewUrl,
  candidateDashboardUrl,
  analyticsUrl,
  customerOverviewUrl,
  getEmpOverviewUrl,
  googleLoginUrl,
  adminPanelUrl
};
