import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Atsemployeedetails from "../../components/candidateDetails/AtsEmployeeDetails";

function AtsCandidatedetails() {
  const navigate = useNavigate();
  const location = useLocation();
  location.header = "/ats/candidate-overview";
  let isLoggedIn = localStorage.getItem("userAuth");
  useEffect(() => {
    let isLoggedIn = localStorage.getItem("userAuth");
    if (isLoggedIn === "false") {
      localStorage.clear();
      localStorage.setItem("userAuth", "false");
      navigate("/");
    }
  }, [isLoggedIn]);
  return (
    isLoggedIn === "true" && (
      <>
        <div>
          <div className="main-wrapper">
            <div className="main-body">{<Atsemployeedetails />}</div>
          </div>
        </div>
      </>
    )
  );
}

export default AtsCandidatedetails;
