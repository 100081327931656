import { getGoogleLoginDetail } from "src/services/login/loginPage";
import { notificationError } from "src/utils/utils";

import { loginSliceActions } from "./loginSlice";

const { setUserLoggedIn } = loginSliceActions;

export const getGoogleLoggedInUser = (payload) => async (dispatch) => {
  const response = await getGoogleLoginDetail(payload);
  if (response.data.status === true && response?.data?.code === 200) {
    localStorage.setItem("token", response.data.data.token);
    localStorage.setItem("user", JSON.stringify(response.data.data));
    localStorage.setItem("userAuth", "true");
    dispatch(setUserLoggedIn(response?.data?.data));
  } else {
    notificationError(response?.data?.message);
    localStorage.clear();
  }
  return response;
};
