import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  candidateDashboard: [],
  employeeDashboard: [],
  isErr: false
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    getDetailsReq: (state) => {
      state.isLoading = true;
      state.isErr = false;
    },
    getDetailsErr: (state) => {
      state.isLoading = false;
      state.isErr = true;
    },

    employeeDashboard: (state, action) => {
      state.employeeDashboard = action.payload;
      state.isLoading = false;
      state.isErr = false;
    }
  }
});
export const analyticsActions = analyticsSlice.actions;

export default analyticsSlice.reducer;
