import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import back from "src/assets/images/back.svg";
import close10 from "src/assets/images/close10.svg";
import close14 from "src/assets/images/close14.svg";
import { candidateSkillSetsArr } from "src/utils/uiConstants";
import { getRequest } from "src/utils/httpHelper";
import { attachParams } from "src/utils/urlHelper";
import { adminPanelUrl, candidateOverviewUrl } from "src/utils/urlContainers";
import { DateRangePicker } from "rsuite";
import moment from "moment/moment";
import {
  dateFormater,
  formatDate,
  getTotalCount,
  isObjEmpty
} from "src/utils/utils";
import Loader from "src/components/loader/index";
import { allStatuses } from "src/constants/addEmployeeConstants";

const Reports = () => {
  const { role } = JSON.parse(localStorage.getItem("user"));

  const today = new Date();
  const oneMonthAgo = new Date(today);
  oneMonthAgo.setDate(today.getDate() - 30);

  const [reportsData, setReportsData] = useState(null);
  const [rejetcedReport, setRejetcedReport] = useState(null);
  const [query, setQuery] = useState({
    startDate: formatDate(oneMonthAgo),
    endDate: formatDate(today)
  });

  const [filterInterviewedBy, setFilterInterviewedBy] = useState([]);
  const [filterTimevalue, setFilterTimeValue] = useState([today, oneMonthAgo]);

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    getAllRoleCandidateData();
  }, []);

  //candidate analitics for candidates existing api
  useEffect(() => {
    setLoading(true);
    getRejectedCandidates();
  }, [query]);

  const getRejectedCandidates = async () => {
    const newQuery = query ? query : {};

    const response = await getRequest({
      url: attachParams(candidateOverviewUrl.REJECTED_CANDIDATE, newQuery)
    });

    setLoading(false);
    setReportsData(response?.data?.data?.currentCandidatesStatus || null);
    setRejetcedReport(response?.data?.data?.rejected || null);
  };

  const getAllRoleCandidateData = async () => {
    const response = await getRequest({
      url: attachParams(adminPanelUrl.GET_ALL_USERS, {
        pageNumber: 1,
        pageSize: 100
      })
    });
    setFilterInterviewedBy(response?.data?.data?.user);
  };

  const handleUpdate = (e) => {
    const { name, value } = e.target;
    setQuery({ ...query, [name]: value });
  };

  const handleDate = (e) => {
    if (e === null) {
      setQuery({ ...query, startDate: "", endDate: "" });
      setFilterTimeValue([]);
    } else {
      const data = { ...query };
      data.startDate = moment(e[0]).format("YYYY/MM/DD");
      data.endDate = moment(e[1]).format("YYYY/MM/DD");
      setQuery(data);
      setFilterTimeValue(e);
    }
  };

  const removeFilter = (key) => {
    const data = { ...query };
    if (key === "startDate" || key === "endDate") {
      delete data["startDate"];
      delete data["endDate"];
      setFilterTimeValue([]);
    } else {
      data[key] = "";
    }
    setQuery(data);
    const params = {};
    for (let val in data) {
      if (data[val] === "") {
        delete data[val];
        continue;
      } else {
        params[val] = data[val];
      }
    }
  };

  const handleClearFilters = () => {
    setQuery({});
    setFilterTimeValue([]);
  };

  return (
    <>
      <div className="heading-section">
        <div className="h-title">
          <Link className="back-to" to="/ats/candidate-overview">
            <img src={back} alt="back" />
          </Link>
          <h4>Candidates Reports</h4>
        </div>
      </div>
      <div>
        <div className={`statusForm filter-over-write-class`}>
          <div className="input-group">
            <label>Job Openings</label>
            <select
              onChange={handleUpdate}
              name="currentJobTitle"
              value={query?.currentJobTitle || ""}
            >
              <option value="">Please Select</option>
              {candidateSkillSetsArr.map((opt, v) => (
                <option key={v} value={opt}>
                  {opt}
                </option>
              ))}
            </select>
          </div>
          <div className="input-group">
            <label>Added by</label>
            <select
              onChange={handleUpdate}
              name="interviewBy"
              value={query?.interviewBy || ""}
            >
              <option value="">Please Select</option>
              {filterInterviewedBy?.length ? (
                filterInterviewedBy.map((opt, v) => (
                  <option key={v} value={opt?.name}>
                    {opt?.name}
                  </option>
                ))
              ) : (
                <option value="No Interviewers ">No Interviewers </option>
              )}
            </select>
          </div>
          <div className="input-group">
            <label>Start and End Date</label>
            <DateRangePicker
              className="w-100 h--50 date_picker_range"
              showOneCalendar={true}
              onChange={(event) => handleDate(event)}
              cleanable={true}
              ranges={[]}
              placeholder={"Please Select"}
              format={"dd-MM-yyyy"}
              defaultValue={filterTimevalue || []}
              value={filterTimevalue || []}
            />
          </div>

          {!isObjEmpty(query) && (
            <p onClick={handleClearFilters} className="clear-btn">
              Clear all filters
            </p>
          )}
        </div>

        {(query?.currentJobTitle || query?.interviewBy || query?.startDate) &&
          role === "Admin" && (
            <div className="chips-parent admin-applied-filter">
              <strong className="Title-Filter">Applied Filter : </strong>
              {Object.keys(query).map((key, i) => {
                if (key === "endDate") {
                  return null;
                } else {
                  return (
                    query[key] !== "" &&
                    (key === "startDate" ? (
                      <span className="chips" key={i}>
                        {dateFormater(query["startDate"], "DD-MM-YYYY")} ~&nbsp;
                        {dateFormater(query["endDate"], "DD-MM-YYYY")}
                        <img
                          src={close10}
                          alt=""
                          onClick={() => {
                            removeFilter(key);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    ) : (
                      <span className="chips" key={i}>
                        {query[key]}
                        <img
                          style={{ cursor: "pointer" }}
                          src={close14}
                          alt=""
                          onClick={() => {
                            removeFilter(key);
                          }}
                        />
                      </span>
                    ))
                  );
                }
              })}
            </div>
          )}

        {role === "Admin" && !isLoading && (
          <>
            <div className="reports-container">
              <ul className="total-wrapper">
                <li className="report-card-total">
                  <p> Total Rejected</p>
                  <span>{rejetcedReport ? getTotalCount(rejetcedReport) : 0}</span>
                </li>
                <li className="report-card-total">
                  <p> Total Joined</p>
                  <span>{(reportsData && reportsData["Joined"]) || 0}</span>
                </li>
              </ul>
              <ul>
                {allStatuses &&
                  allStatuses.map((item, index) => (
                    <li key={index} className="report-card">
                      <p> {item}</p>
                      <span>
                        {" "}
                        Current &nbsp;&nbsp; :{" "}
                        <span className="count">
                          {(reportsData && reportsData[item]) || 0}
                        </span>
                      </span>
                      <span>
                        {" "}
                        Rejected &nbsp;:{" "}
                        <span className="count">
                          {(rejetcedReport && rejetcedReport[item]) || 0}
                        </span>
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
          </>
        )}
        {isLoading ? (
          <Loader
            loaderStyles={{ marginTop: "20px", height: "calc(100vh - 273px)" }}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Reports;
