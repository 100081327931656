import React, { useEffect, useState } from "react";
import Atsstatus from "src/components/popup/atsPopups/AtsStatus";
import deleteIcon from "src/assets/images/delete.svg";
import { dateFormater } from "src/utils/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  deletCandidateStatus,
  getAllPipelineDetails
} from "src/redux/candidateOverview/action";

function Atspipeline(props) {
  const { overWriteClass, onChangeStatus, isDrawer } = props;
  const dispatch = useDispatch();
  const { pipelineDetails } = useSelector((state) => state?.candidateOverview);
  const { open, setOpen } = props;
  const [statusData, setStatusData] = useState([]);
  const roleUser = JSON.parse(localStorage.getItem("user"));
  const { role } = roleUser ?? "";

  const getData = async () => {
    await dispatch(getAllPipelineDetails({ _id: props.id }));
  };

  const deleteStatus = async (item) => {
    await dispatch(
      deletCandidateStatus({
        _id: item._id,
        candidateId: item.candidateId
      })
    );
    getData();
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (pipelineDetails) {
      let temp = [...pipelineDetails];
      temp.reverse();
      setStatusData(temp);
    }
  }, [pipelineDetails]);

  const closePopup = () => {
    setOpen(false);
    getData();
  };

  return (
    <>
      {open ? (
        <Atsstatus
          close={() => {
            closePopup();
          }}
          setRender={() => {
            getData();
            props.setUpdated();
          }}
          id={props.id}
          candidate={pipelineDetails[pipelineDetails.length - 1]}
          onChangeStatus={() => (isDrawer ? onChangeStatus?.() : {})}
        />
      ) : (
        ""
      )}

      {statusData && (
        <>
          {statusData.length > 0 &&
            statusData.map((ele, i) => {
              return (
                <div
                  className={`pipeline ${overWriteClass ? overWriteClass : ""} `}
                  key={i}
                >
                  <div className="pipelineDetails">
                    <div
                      className={
                        role === "Admin" || role === "Manager" ? "headder" : "abcd"
                      }
                    >
                      <h4>Status : {ele.status}</h4>
                      <div className="date-wrapper">
                        {overWriteClass && (
                          <p>{dateFormater(ele.date, "DD MMM YYYY")}</p>
                        )}
                        {(role === "Admin" || role === "Manager") && (
                          <img
                            onClick={() => {
                              deleteStatus(ele);
                            }}
                            src={deleteIcon}
                            alt=""
                          />
                        )}
                      </div>
                    </div>

                    {!overWriteClass && (
                      <p>Date : {dateFormater(ele.date, "DD MMM YYYY")}</p>
                    )}
                    {ele.interviewBy ? (
                      <p>Added By : {ele.interviewBy}</p>
                    ) : (
                      <p>Modified By : {ele.modifiedBY}</p>
                    )}
                    <p style={{ display: "flex" }}>
                      Remarks :{" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ele.remarks ? ele.remarks : "---"
                        }}
                      ></span>
                    </p>
                  </div>
                </div>
              );
            })}
        </>
      )}
    </>
  );
}

export default Atspipeline;
