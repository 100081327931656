import React from "react";
import "src/components/noDataFound/NoDataFound.css";
import nodatafound from "src/assets/images/nodata.jpg";

const NoDataFound = () => {
  return (
    <div className="nodata-section">
      <img src={nodatafound} alt="back" />
      <h4>No Data Found</h4>
    </div>
  );
};

export default NoDataFound;
