import React, { useState } from "react";
import { useSelector } from "react-redux";
import back from "src/assets/images/close icon.svg";
import Atspipeline from "src/components/candidateDetails/AtsPipeline";

const PipeLineDrawer = ({ handleCloseDrawer, id, onChangeStatus }) => {
  const { candidateDetails } = useSelector((state) => state?.candidateOverview);
  const [open, setOpen] = useState(false);

  const openHandler = () => {
    setOpen(true);
  };
  return (
    <>
      <div className="statusModal">
        <div className="hrModal">
          <div className="statusHead">
            <h4>Pipeline</h4>
            <div>
              <div className="status-btn-wrapper">
                <button className="btn-blue" onClick={openHandler}>
                  Change Status
                </button>
              </div>
              <img width={30} src={back} onClick={handleCloseDrawer} alt="back" />
            </div>
          </div>
          <Atspipeline
            id={id}
            details={candidateDetails}
            open={open}
            setOpen={setOpen}
            openHandler={openHandler}
            overWriteClass="overWriteClass"
            onChangeStatus={onChangeStatus}
            isDrawer={true}
          />
        </div>
      </div>
    </>
  );
};

export default PipeLineDrawer;
