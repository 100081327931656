import React, { useEffect, useState } from "react";
import { usePagination, DOTS } from "src/components/pagination/usePagination";
import "src/components/pagination/pagination.css";

const Pagination = ({ totalCount, activePage, pageSize, onPageChange }) => {
  const [rowSize, setRowSize] = useState(pageSize);
  const paginationRange = usePagination({
    activePage,
    totalCount,
    pageSize: rowSize
  });

  let lastPage = paginationRange[paginationRange?.length - 1];

  const prevPage = () => {
    if (activePage < 1) {
      return;
    }
    onPageChange(activePage - 1, rowSize);
  };

  const nextPage = () => {
    if (activePage === lastPage) {
      return;
    }
    onPageChange(activePage + 1, rowSize);
  };

  const handleRowChange = (e) => {
    const rowNumber = Number(e.target.value);
    setRowSize(rowNumber);
    onPageChange(1, rowNumber);
  };

  useEffect(() => {
    setRowSize(pageSize);
  }, [pageSize]);

  return (
    <div className="pagination_wrapper">
      <div className="pagination">
        <button
          onClick={prevPage}
          className={`${activePage === 1 ? "disabled" : ""}`}
          disabled={activePage === 1}
        >
          &laquo;
        </button>
        {paginationRange.map((ele, active) => {
          if (ele === DOTS) {
            return (
              <button disabled key={active} className="pagination-item dots">
                &#8230;
              </button>
            );
          }

          return (
            <button
              className={`${activePage === ele ? "active" : ""}`}
              key={active}
              onClick={() => onPageChange(ele, rowSize)}
            >
              {ele}
            </button>
          );
        })}

        <button
          className={`${activePage === lastPage ? "disabled" : ""}`}
          onClick={nextPage}
        >
          &raquo;
        </button>
      </div>
      <div className="select_rowAction">
        <select
          name="totalMonthOfExperience"
          onChange={(e) => handleRowChange(e)}
          value={rowSize}
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
    </div>
  );
};
export default Pagination;
