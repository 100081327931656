import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userLoggedIn: {}
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setUserLoggedIn: (state, action) => {
      state.userLoggedIn = action?.payload;
    }
  }
});

export const loginSliceActions = loginSlice.actions;

export default loginSlice.reducer;
