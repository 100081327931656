import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dashboardData: null,
  loader: false,
  dashboardOfferAccepted: [],
  tech: "Technology"
};

export const candidateDashboardSlice = createSlice({
  name: "candidateDashboard",
  initialState,
  reducers: {
    getLoader: (state) => {
      state.loader = !state.loader;
    },
    getDashboardData: (state, action) => {
      state.dashboardData = action.payload;
    },
    getOfferAccepted: (state, action) => {
      state.dashboardOfferAccepted = action.payload;
    }
  }
});

export const candidateDashboardActions = candidateDashboardSlice.actions;

export default candidateDashboardSlice.reducer;
