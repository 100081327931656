import React, { useEffect } from "react";
import back from "src/assets/images/close icon.svg";

import "./existingCandidate.css";
import { Link } from "react-router-dom";
import { dateFormater } from "src/utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { getAllPipelineDetails } from "src/redux/candidateOverview/action";

const ExistingCandidateModal = ({ onClose, userData }) => {
  const dispatch = useDispatch();
  const { role } = JSON.parse(localStorage.getItem("user"));
  const { pipelineDetails } = useSelector((state) => state?.candidateOverview);

  const getData = async () => {
    await dispatch(getAllPipelineDetails({ _id: userData?._id }));
  };

  useEffect(() => {
    getData();
  }, [userData?._id]);

  return (
    <>
      <div className="statusModal existing-user-modal">
        <div className="hrModal">
          <div className="statusHead">
            <h3>{"Duplicate Check Details"}</h3>
            <img src={back} onClick={() => onClose()} alt="back" width={30} />
          </div>
          <ul>
            <li>
              Fullname :{" "}
              <p>
                {" "}
                {userData.firstName} {userData.lastName}
              </p>
            </li>
            <li>
              Id :{" "}
              <Link
                className="p_name "
                to={
                  role === "Consultancy"
                    ? ""
                    : `/ats/candidate-details/${userData?._id}`
                }
              >
                {" "}
                {userData.candidateID}
              </Link>
            </li>
            <li>
              Phone Number : <p> {userData.phoneNumber} </p>
            </li>
            <li>
              Email : <p> {userData.email}</p>
            </li>
          </ul>
          <div className="existing-user-pipeline">
            <h3 className="pipeline-title">Pipeline Details: </h3>
            {pipelineDetails?.length > 0 &&
              pipelineDetails?.map((ele, index) => (
                <div className={`pipeline`} key={index}>
                  <div className="pipelineDetails">
                    <h4>Status : {ele.status}</h4>
                    <p>
                      Date :{" "}
                      {ele?.date ? dateFormater(ele.date, "DD MMM YYYY") : "-"}
                    </p>
                    {ele.interviewBy ? (
                      <p>Added By : {ele.interviewBy}</p>
                    ) : (
                      <p>Modified By : {ele.modifiedBY}</p>
                    )}
                    <p style={{ display: "flex" }}>
                      Remarks :{" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ele.remarks ? ele.remarks : "---"
                        }}
                      ></span>
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ExistingCandidateModal;
