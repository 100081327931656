export const sideBarData = [
  {
    dashBoardTitle: "ats",
    routing: "/ats/dashboard",

    children: [
      { subChild: "Dashboard", routing: "/ats/dashboard" },
      { subChild: "Candidates", routing: "/ats/candidate-overview" }
    ]
  }
];

export const sideBarAdminData = [
  {
    dashBoardTitle: "Admin Panel",
    routing: "/admin-panel"
  },
  {
    dashBoardTitle: "Reports",
    routing: "/reports"
  }
];
