import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  candidateList: [],
  totalCount: 0,
  loader: false,
  candidateDetails: {},
  pipelineDetails: []
};

export const candidateOverviewSlice = createSlice({
  name: "candidateOverview",
  initialState,
  reducers: {
    getLoader: (state) => {
      state.loader = !state.loader;
    },
    getAllCandidateList: (state, action) => {
      state.candidateList = action?.payload.getCandidates;
      state.totalCount = action?.payload?.totalCount ?? 0;
    },
    setSearchCandidateList: (state, action) => {
      state.candidateList = action?.payload?.searchResults ?? [];
      state.totalCount = action?.payload?.totalCount ?? 0;
    },
    getCandidateDetail: (state, action) => {
      state.candidateDetails = action?.payload.getCandidates;
    },
    setPipelineDetails: (state, action) => {
      state.pipelineDetails = action?.payload?.hiringStatus ?? [];
    }
  }
});

export const candidateOverviewActions = candidateOverviewSlice.actions;

export default candidateOverviewSlice.reducer;
